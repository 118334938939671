import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';


const ConfirmationDialog = ({ open, onClose, onConfirm, title, content }) => {
  const { t, i18n } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title" style={{color:'red'}}>{title}</DialogTitle>
      <DialogContent>
        <Typography id="confirmation-dialog-description" style={{fontSize:'14px'}}>{content}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{color:'black'}}>
          {t('Cancel')}
        </Button>
        <Button
          onClick={onConfirm}
          color="error"
          style={{background:'red',color:'white'}}
          variant="contained"
        >
          {t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
