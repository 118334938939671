import React, { useState, useEffect } from "react";
import {
  Select,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Typography,
  Box,
  Button,
  Card,
  Grid,
  colors,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import ArgonBox from "components/ArgonBox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationDialog from 'components/Delete';
import CircularProgress from "@mui/material/CircularProgress";
import { BASE_URL } from "endpoint";
import ArgonTypography from "components/ArgonTypography";
import Approver from './approver'
import "./jobs.css";
import { useTranslation } from 'react-i18next';


const ITEM_HEIGHT = 48;

const StyledCard = styled(Card)(({ theme }) => ({
  border: `2px dashed ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  textAlign: "center",
  cursor: "pointer",
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));
const MenuButton = (props) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t, i18n } = useTranslation();
  return (
    <span style={{ float: "right" }}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/jobs", { state: { clientId: props?.id ,clientDetails:props?.item} });
            handleClose();
          }}
          style={{ color: '#288de7', fontWeight: '600' }}
        >
          <VisibilityIcon style={{ marginRight: '9px' }} />  {t(' View Jobs')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/createjobs", { state: { clientId: props?.id } });
            handleClose();
          }}
          style={{ color: '#40c057', fontWeight: '600' }}
        >
          <AddIcon style={{ marginRight: '5px', fontSize: '18px !important' }} />  {t('New Job')}
        </MenuItem>
        <hr style={{ borderColor: '#cdcdcd4d' }} />
        <MenuItem
          onClick={() => {
            props.handleClickOpen()
            handleClose();
          }}
          style={{ color: '#858e96', fontWeight: '600' }}
        >
          <EditIcon style={{ marginRight: '5px', fontSize: '18px !important' }} /> {t('Edit')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            props?.handelApprover()
            handleClose();
          }}
          style={{ color: '#858e96', fontWeight: '600' }}
        >
          <EditIcon style={{ marginRight: '5px', fontSize: '18px !important' }} />  {t('Approval Flow')}
        </MenuItem>
        <hr style={{ borderColor: '#cdcdcd4d' }} />
        <MenuItem
          onClick={() => {
            props?.deleteJob(props?.id);
            handleClose();
          }}
          style={{ color: '#fa5352', fontWeight: '600' }}
        >
          <DeleteIcon style={{ marginRight: '9px', fontSize: '18px !important' }} /> {t('Inactive')}
        </MenuItem>
      </Menu>
    </span>
  );
};

function Tables() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [clientDetails, setClientDetails] = useState([]);
  const [teamDetails, setTeamDetails] = useState([]);
  const [editId, setEditId] = useState();
  const [editImage, setEditImage] = useState();
  const [levels, setLevels] = useState([]);
  const [createPopUpTitle, setCreatePopUpTitle] = useState('');
  const [alignment, setAlignment] = useState("individual");
  const [alignmentStatus, setAlignmentStatus] = useState("active");
  const [formData, setFormData] = useState({
    customerName: "",
    teams: "",
  });
  const { t, i18n } = useTranslation();
  const [files, setFiles] = useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);


  const [openApprover, setOpenApprover] = useState(false);

  const handleOpenApprover = () => {
    setOpenApprover(true);
  };

  const handleCloseApprover = () => {
    setOpenApprover(false);
  };

  const handleDelete = () => {
    setIsDeleteOpen(true);
  };

  const handleConfirmDelete = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token"); // Get token from local storage
      const response = await fetch(`${BASE_URL}jobs/updateCustomerStatus/${editId}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`, // Set bearer token in the header
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status: 'inactive'
        }),
      });

      if (response.ok) {
        const result = await response.text(); // Or `response.json()` if the server responds with JSON
        toast.success(t("Satus updated successfully!"));
        getClients()
        // Handle success (e.g., show a message or redirect)
      } else {
        toast.error(t("An error occurred. Please try again."));
        // Handle error
      }
    } catch (error) {
      console.error(t("An error occurred. Please try again."), error);
      toast.error(t("An error occurred. Please try again."));      // Handle error
    } finally {
      // Hide loader
      setLoading(false);
    }
    setIsDeleteOpen(false);
  };

  const handleCloseDialog = () => {
    setIsDeleteOpen(false);
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const handleChangeSatus = (event, newAlignment) => {
    setAlignmentStatus(newAlignment);
  };
  const handleClickOpen = (title) => {
    setCreatePopUpTitle(title)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.target.files);
    setEditImage('')
    setFiles([...newFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileSelect = (event) => {
    const newFiles = Array.from(event.target.files);
    setEditImage('')
    setFiles([...newFiles]);
  };

  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const getClients = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token"); // Get token from local storage
      const response = await fetch(`${BASE_URL}jobs/getAllCustomerInfo`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,// Set bearer token in the header

        },
      });

      if (response.ok) {
        const result = await response.json(); 
        setClientDetails(result)
      } else {
        toast.error(t("An error occurred. Please try again."));
      }
    } catch (error) {
      toast.error(t("An error occurred. Please try again."));
        } finally {
      // Hide loader
      setLoading(false);
    }
  };


  const getTeams = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${BASE_URL}teams/getTeams`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const result = await response.json();
        setTeamDetails(result)

      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // toast.error("An error occurred. Please try again.");
    } finally {
      // Hide loader
      setLoading(false);
    }
  };
  useEffect(() => {
    getClients();
    getTeams()
  }, []);

  const createCustomerInfo = async () => {
    const formDataS = new FormData();

    // Append form fields
    formDataS.append("type", alignment);
    formDataS.append("customerName", formData.customerName);
    formDataS.append("exclusiveTeams", formData.teams || "");
    formDataS.append("status", alignmentStatus);

    // Append logo file if it exists
    if (files) {
      files?.map((file) => {
        formDataS.append("logo", file);
      });
    }
    setLoading(true);
    try {
      const token = localStorage.getItem("token"); // Get token from local storage
      const response = await fetch(`${BASE_URL}jobs/createCustomerInfo`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Set bearer token in the header
        },
        body: formDataS,
      });

      if (response.ok) {
        const result = await response.text(); // Or `response.json()` if the server responds with JSON
        toast.success(t("Client created successfully!"));
        getClients()
        setFormData({});
        handleClose();
        // Handle success (e.g., show a message or redirect)
      } else {
        console.error("Failed to create customer info:", await response.text());
        toast.error(t("An error occurred. Please try again."));
        // Handle error
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error(t("An error occurred. Please try again."));
            // Handle error
    } finally {
      // Hide loader
      setLoading(false);
    }
  };
  const editCustomerInfo = async () => {
    const formDataS = new FormData();

    // Append form fields
    formDataS.append("type", alignment);
    formDataS.append("customerName", formData.customerName);
    formDataS.append("exclusiveTeams", formData.teams || "");
    formDataS.append("status", alignmentStatus);

    // Append logo file if it exists
    if (files) {
      files?.map((file) => {
        formDataS.append("logo", file);
      });
    }

    try {
      const token = localStorage.getItem("token"); // Get token from local storage
      const response = await fetch(`${BASE_URL}jobs/updateCustomerInfo/${editId}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`, // Set bearer token in the header
        },
        body: formDataS,
      });

      if (response.ok) {
        const result = await response.text(); // Or `response.json()` if the server responds with JSON
        toast.success(t("Client updated successfully!"));
        getClients()
        setFormData({});
        handleClose();
        // Handle success (e.g., show a message or redirect)
      } else {
        console.error("Failed to create customer info:", await response.text());
        toast.error(t("An error occurred. Please try again."));
        // Handle error
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error(t("An error occurred. Please try again."));
            // Handle error
    }
  };

  return (
    <DashboardLayout>
      {loading && <Box sx={{ position: 'fixed', height: "100vh", width: "100%", background: '#00000040', display: 'flex', top: '0', left: '0', zIndex: 999999 }}>
        <CircularProgress style={{ margin: "auto" }} />
      </Box>}
      <DashboardNavbar />
      <ToastContainer />
      <ArgonBox py={3} className="jobs">
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="info"
              style={{ float: "right" }}
              onClick={() => {
                // sendMsg()
                setAlignment('individual')
                setAlignmentStatus('active')
                setFormData({
                  customerName: "",
                  teams: ""
                }) 
                handleClickOpen("Create")
              }}
              startIcon={<AddIcon style={{ fontWeight: "bolder" }} />}
            >
              {t('Create Client')}
            </Button>
          </Grid>




          {clientDetails?.map((item, index) => {
            const totalJobs = parseInt(item.totalJobs, 10);
            const activeAndPendingJobs = parseInt(item.activeJobs, 10) + parseInt(item.pendingJobs, 10);
            const completedJobs = parseInt(item.completedJobs, 10);
            const adjestmentJobs = parseInt(item.adjestmentJobs, 10);

            // Calculate width percentages
            const approvedWidth = (completedJobs / totalJobs) * 100 + "%";
            const waitingForApprovalWidth = (activeAndPendingJobs / totalJobs) * 100 + "%";
            const adjustmentWidth = (adjestmentJobs / totalJobs) * 100 + "%";


            return (
              <Grid item xs={4} style={{}}>
                <ArgonBox mb={3}>
                  <Card
                    key={index}
                    style={{
                      margin: "10px",
                      boxShadow: "0px 0px 3px 3px lightgrey",
                      borderRadius: "5px"
                    }}
                  >
                    <ArgonBox pb={1} pt={2} style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                      <span>
                        <img 
                        src={item?.logo?BASE_URL + item?.logo:'./user.jpeg'} 
                        style={{
                          height: '55px',
                          width: '55px',
                          boxShadow: "0px 0px 3px 3px lightgrey",
                          borderRadius: '5px',
                          marginLeft: '10px'
                        }} />
                        <ArgonTypography
                          display="inline"
                          variant="span"
                          textTransform="capitalize"
                          px={2}
                          style={{ fontSize: "14px", fontWeight: "600", position: "relative", top: "-20px" }}
                        >
                          {item?.customerName}
                        </ArgonTypography>
                      </span>
                      <MenuButton
                        id={item?.id}
                        item={item}
                        deleteJob={() => {
                          setEditId(item?.id)
                          handleDelete()
                        }}
                        handleClickOpen={() => {
                          setAlignment(item?.type)
                          setAlignmentStatus(item?.status)
                          setFormData({
                            customerName: item?.customerName,
                            teams: item?.exclusiveTeams
                          })
                          setEditImage(BASE_URL + item?.logo)
                          setEditId(item?.id)
                          handleClickOpen("Edit")
                        }}
                        handelApprover={() => {
                          setLevels(item?.levels)
                          setEditId(item?.id)
                          handleOpenApprover();
                        }}
                      />
                    </ArgonBox>
                    <hr />
                    <ArgonBox pb={1} pt={2} px={2} >
                      <ArgonTypography
                        display="inline"
                        variant="p"
                        textTransform="capitalize"
                        px={2}
                        style={{ fontSize: "18px", fontWeight: "600", position: "relative", color: 'grey' }}
                      >
                        {item?.totalJobs} {t('jobs completed')}
                      </ArgonTypography>

                      <ArgonBox pb={1} style={{ borderRadius: '5px', height: '35px' }}>
                        {item?.totalJobs > 0 && <>  <span
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: 'white',
                            background: 'grey',
                            width: waitingForApprovalWidth,
                            display: 'inline-block',
                            textAlign: 'center',
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            borderRadius: waitingForApprovalWidth === "100%" ? '5px' : '5px 0px 0px 5px'
                          }}
                        >
                          {t('Waiting for approval')}
                        </span>
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: "600",
                              color: 'white',
                              background: '#fab007',
                              width: adjustmentWidth,
                              display: 'inline-block',
                              textAlign: 'center',
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              borderRadius: adjustmentWidth === "100%" ? '5px' : '0px 0px 0px 0px'
                            }}
                          >
                            {t('In adjestment')}
                          </span>
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: "600",
                              color: 'white',
                              background: '#40c057',
                              width: approvedWidth,
                              display: 'inline-block',
                              textAlign: 'center',
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              borderRadius: approvedWidth === "100%" ? '5px' : '0px 5px 5px 0px'
                            }}
                          >
                            {t('Approved')}
                          </span></>}
                      </ArgonBox>
                      <Stack direction="row" spacing={1}>
                        <Chip label={t("ACTIVE")} color="success" style={{ color: 'white', fontWeight: '600', padding: "2px 5px", height: '28px' }} />
                        {item?.levels?.length > 0 ?'': <Chip label={t("APPROVAL FLOW")} onClick={() => {
                          setEditId(item?.id)
                          handleOpenApprover();
                        }} icon={<ReportProblemIcon />} color="primary" style={{ color: 'white', background: "#fab005", fontWeight: '600', padding: "2px 5px", height: '28px', cursor: 'pointer' }} />}
                      </Stack>
                    </ArgonBox>
                  </Card>
                </ArgonBox>
              </Grid>
            );
          })}

        </Grid>
      </ArgonBox>
      {/* <Footer /> */}
      <Dialog open={open} onClose={handleClose} fullWidth className="client">
        <DialogTitle>{createPopUpTitle} {t('Client')}</DialogTitle>
        <DialogContent>
          <ArgonBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={1}
            pt={1}
          >
           
          </ArgonBox>
            <>
              <ArgonBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={1}
                pt={1}
              >
                <FormControl fullWidth>
                  <StyledCard
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    sx={{
                      minHeight: "100px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      {t('Drag or Click to select the file')}
                    </Typography>
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={(e) => handleFileSelect(e)}
                      style={{ display: "none" }}
                      id="file-input"
                    />
                    <label htmlFor="file-input">
                      <Button
                        variant="contained"
                        component="span"
                        sx={{ marginTop: 2 }}
                        style={{ color: "white" }}
                      >
                        {t('Select Images')}
                      </Button>
                    </label>
                    {editImage ? <>
                      <Box
                        sx={{
                          marginTop: 2,
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 2,
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            display: "inline-block",
                            margin: "10px",
                          }}
                        >

                          <img
                            src={editImage}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              borderRadius: "8px",
                            }}
                          />
                        </div>

                      </Box>
                    </> : <>
                      {files?.length > 0 && (
                        <Box
                          sx={{
                            marginTop: 2,
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 2,
                          }}
                        >

                          {files.map((file, index) => (
                            <div
                              key={file.name}
                              style={{
                                position: "relative",
                                display: "inline-block",
                                margin: "10px",
                              }}
                            >

                              <img
                                src={URL.createObjectURL(file)}
                                alt={file.name}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                  borderRadius: "8px",
                                }}
                              />
                              <IconButton
                                style={{
                                  position: "absolute",
                                  top: "5px",
                                  right: "5px",
                                  height: "25px",
                                  width: "25px",
                                  color: "white",
                                  backgroundColor: "black",
                                  padding: "5px",
                                }}
                                onClick={() => handleRemoveFile(file.name)}
                              >
                                <ClearIcon />
                              </IconButton>
                            </div>
                          ))}
                        </Box>
                      )}
                    </>}

                  </StyledCard>
                </FormControl>
              </ArgonBox>
              <ArgonBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={1}
                pt={1}
              >
                <FormControl fullWidth>
                  <label htmlFor="jobName">{t('Customer name')}</label>
                  <input
                    type="text"
                    id="jobName"
                    name={t("Jobs")}
                    placeholder={t("Enter Customer Name")}
                    value={formData.customerName}
                    onChange={(e) =>
                      setFormData({ ...formData, customerName: e.target.value })
                    }
                  />
                </FormControl>
              </ArgonBox>

              <ArgonBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={1}
                pb={1}
              >
                <FormControl fullWidth>
                  <label htmlFor="client">{t('Exclusive teams')}</label>
                  <select
                    id="client"
                    name={t("client")}
                    value={formData.teams}
                    onChange={(e) =>
                      setFormData({ ...formData, teams: e.target.value })
                    }
                  >
                    <option value="">{t('Select teams')}</option>
                    {teamDetails?.map((item)=> <option value={item.id}>{item?.name}</option>)}
                  </select>
                </FormControl>
              </ArgonBox>
              <ArgonBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={1}
                pb={1}
              >
                <FormControl fullWidth>
                  <label htmlFor="client">{t('Status')}</label>
                  <ToggleButtonGroup
                    color="primary"
                    value={alignmentStatus}
                    exclusive
                    onChange={handleChangeSatus}
                    aria-label="Platform"
                  >
                    <ToggleButton value="active" style={{ width: "50%" }}>
                      {t('Active')}
                    </ToggleButton>
                    <ToggleButton value="inactive" style={{ width: "50%" }}>
                    {t('Inactive')} 
                    </ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
              </ArgonBox>
            </>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: "red" }} onClick={handleClose}>{t('Cancel')}</Button>
          <Button onClick={createPopUpTitle === "Edit" ? editCustomerInfo : createCustomerInfo}>{alignment == "individual" ? createPopUpTitle === "Edit" ? "Update" : "Create" : "Upload"}</Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        open={isDeleteOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        title={t('Confirm Inactive')}
        content={t('Are you sure you want to inactive this client? This action cannot be undone.')}
      />

      <Approver open={openApprover}
        handleClose={handleCloseApprover}
        customerId={editId}
        levelsData={levels}
        setLoading={setLoading}
        getData={getClients}
      />
    </DashboardLayout>
  );
}

export default Tables;
