
import Grid from "@mui/material/Grid";
import {
  Button,
  FormControl,
  Typography,
  Box,
  IconButton,
  Card,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";
import Calendar from "./calendar/index";

// Argon Dashboard 2 MUI base styles
import typography from "assets/theme/base/typography";
import AddIcon from "@mui/icons-material/Add";
// Dashboard layout components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import { BASE_URL } from "endpoint";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import "../tables/jobs.css";


const StyledCard = styled(Card)(({ theme }) => ({
  border: `2px dashed ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  textAlign: "center",
  cursor: "pointer",
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

function Default() {
  const { size } = typography;
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    customerName: "",
    teams: "",
  });
  const [files, setFiles] = useState([]);
  const [alignment, setAlignment] = useState("individual");
  const [alignmentStatus, setAlignmentStatus] = useState("active");
  const [pending,setPending]= useState(0);
  const [adjustment,setAdjustment]= useState(0);
  const [approved,setApproved]= useState(0);


  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change the language
  };
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const getJobs = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token"); // Get token from local storage
      const response = await fetch( `${BASE_URL}jobs/getJobs`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, // Set bearer token in the header
        },
      });

      if (response.ok) {
        const result = await response.json(); // Or `response.json()` if the server responds with JSON

        const watingApproval = result?.filter(
          (item) => (item?.status === "approval_pending"||item?.status === "Draft")
        );
        const finalApproved = result?.filter(
          (item) => item?.status === "approved"
        );
        const finalListAdjustment = result?.filter(
          (item) => item?.status === "adjustment"
        );

        
        if (watingApproval?.length > 0) {
          setPending(watingApproval?.length)
        }
        if (finalApproved?.length > 0) {
          setApproved(finalApproved?.length );
        }
        if (finalListAdjustment?.length > 0) {
          setAdjustment(finalListAdjustment?.length);
        }
      } else {
        // toast.error("Failed to create job. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      // Hide loader
      setLoading(false);
    }
  };


  useEffect(() => {
      getJobs();
  }, []);






  const handleChangeSatus = (event, newAlignment) => {
    setAlignmentStatus(newAlignment);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleFileSelect = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const createCustomerInfo = async () => {
    const formDataS = new FormData();

    // Append form fields
    formDataS.append("type", alignment);
    formDataS.append("customerName", formData.customerName);
    formDataS.append("exclusiveTeams", formData.teams || "");
    formDataS.append("status", alignmentStatus);

    // Append logo file if it exists
    if (files) {
      files?.map((file) => {
        formDataS.append("logo", file);
      });
    }

    try {
      const token = localStorage.getItem("token"); // Get token from local storage
      const response = await fetch(`${BASE_URL}jobs/createCustomerInfo`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Set bearer token in the header
        },
        body: formDataS,
      });

      if (response.ok) {
        const result = await response.text(); // Or `response.json()` if the server responds with JSON
        toast.success("Client created successfully!");
        setFormData({});
        handleClose();
        // Handle success (e.g., show a message or redirect)
      } else {
        console.error("Failed to create customer info:", await response.text());
        toast.error("Failed to create customer info!");
        // Handle error
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error("An error occurred!");
      // Handle error
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer />
      <ArgonBox py={3}>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="info"
              style={{ float: "right" }}
              onClick={() => handleClickOpen()}
              startIcon={<AddIcon style={{ fontWeight: "bolder" }} />}
            >
               {t('Create Client')}
            </Button>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title={t('Pending Jobs')}
              count={pending}
              icon={{
                color: "info",
                component: <i className="ni ni-money-coins" />,
              }}
              // percentage={{ color: "success", count: "+55%", text: "since yesterday" }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title={t('Adjustment requests')}
              count={adjustment}
              icon={{
                color: "error",
                component: <i className="ni ni-world" />,
              }}
              // percentage={{ color: "success", count: "+3%", text: "since last week" }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title={t('Customer approved')}
              count={approved}
              icon={{
                color: "success",
                component: <i className="ni ni-paper-diploma" />,
              }}
              // percentage={{ color: "error", count: "-2%", text: "since last quarter" }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title={t('Total jobs performed')}
              count={pending+adjustment+approved}
              icon={{
                color: "warning",
                component: <i className="ni ni-cart" />,
              }}
              // percentage={{ color: "success", count: "+5%", text: "than last month" }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={5} mb={3} mt={5}>
          <Grid item xs={12}>
            <Calendar />
          </Grid>
        </Grid>
      </ArgonBox>
      <Dialog open={open} onClose={handleClose} fullWidth className="client">
        <DialogTitle>{t('Create Client')}</DialogTitle>
        <DialogContent>
            <>
              <ArgonBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={1}
                pt={1}
              >
                <FormControl fullWidth>
                  <StyledCard
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    sx={{
                      minHeight: "100px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                    {t('Drag or Click to select the file')}
                    </Typography>
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      onChange={(e) => handleFileSelect(e)}
                      style={{ display: "none" }}
                      id="file-input"
                    />
                    <label htmlFor="file-input">
                      <Button
                        variant="contained"
                        component="span"
                        sx={{ marginTop: 2}}
                        style={{color:"white"}}
                      >
                        {t('Select Images')}
                      </Button>
                    </label>
                    {files?.length > 0 && (
                      <Box
                        sx={{
                          marginTop: 2,
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 2,
                        }}
                      >
                        {files.map((file, index) => (
                          <div
                            key={file.name}
                            style={{
                              position: "relative",
                              display: "inline-block",
                              margin: "10px",
                            }}
                          >
                            <img
                              src={URL.createObjectURL(file)}
                              alt={file.name}
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "cover",
                                borderRadius: "8px",
                              }}
                            />
                            <IconButton
                              style={{
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                                height: "25px",
                                width: "25px",
                                color: "white",
                                backgroundColor: "black",
                                padding: "5px",
                              }}
                              onClick={() => handleRemoveFile(file.name)}
                            >
                              <ClearIcon />
                            </IconButton>
                          </div>
                        ))}
                      </Box>
                    )}
                  </StyledCard>
                </FormControl>
              </ArgonBox>
              <ArgonBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={1}
                pt={1}
              >
                <FormControl fullWidth>
                  <label htmlFor="jobName">{t('Customer name')}</label>
                  <input
                    type="text"
                    id="jobName"
                    name="jobName"
                    placeholder="Enter Customer Name"
                    value={formData.customerName}
                    onChange={(e) =>
                      setFormData({ ...formData, customerName: e.target.value })
                    }
                  />
                </FormControl>
              </ArgonBox>

              <ArgonBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={1}
                pb={1}
              >
                <FormControl fullWidth>
                  <label htmlFor="client">{t('Exclusive teams')}</label>
                  <select
                    id="client"
                    name="client"
                    value={formData.teams}
                    onChange={(e) =>
                      setFormData({ ...formData, teams: e.target.value })
                    }
                  >
                    <option value="">{t('Select teams')}</option>
                    <option value="Client A">Team A</option>
                    <option value="Client B">Team B</option>
                    {/* Add more options as needed */}
                  </select>
                </FormControl>
              </ArgonBox>
              <ArgonBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={1}
                pb={1}
              >
                <FormControl fullWidth>
                  <label htmlFor="client">{t('Status')}</label>
                  <ToggleButtonGroup
                    color="primary"
                    value={alignmentStatus}
                    exclusive
                    onChange={handleChangeSatus}
                    aria-label="Platform"
                  >
                    <ToggleButton value="active" style={{ width: "50%" }}>
                    {t('Active')}
                    </ToggleButton>
                    <ToggleButton value="inactive" style={{ width: "50%" }}>
                    {t('Inactive')}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
              </ArgonBox>
            </>
        </DialogContent>
        <DialogActions>
          <Button style={{color:"red"}} onClick={handleClose}>{t('Cancel')}</Button>
          <Button onClick={createCustomerInfo}>{alignment == "individual" ? "Create" : "Upload"}</Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default Default;
