import { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";
import { BASE_URL } from "endpoint";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useTranslation } from 'react-i18next';

// const bgImage = "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg";

function Illustration() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState('');
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  useEffect(() => {
    const lang = localStorage.getItem("selectedLang");

    if (lang) {
      setLanguage(lang); 
      i18n.changeLanguage(lang); 
    }

  }, [i18n]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const userData = {
      email,
      password,
    };
    localStorage.setItem("email", email);
    try {
      const response = await fetch(`${BASE_URL}signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", JSON.stringify(data?.user));
        toast.success(t('Signed in successfully!'));
        setTimeout(() => {
          navigate("/dashboard");
          window.location.reload();
        }, 2000); // Wait for 2 seconds before navigating
      } else {
        toast.error(t('Invalid email or password.'));
      }
    } catch (error) {
      toast.error(t('Network error. Please try again'));
    } finally {
      setLoading(false);
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
    localStorage.setItem('selectedLang', lng); 
  };

  const handleLanguageChange = (event) => {
    changeLanguage(event.target.value);
    setLanguage(event.target.value);
  };

  return (
    <div style={{ background: "white", paddingTop: "2vh" }}>
      <IconButton
        size="small"
        color= "white" 
        aria-controls="language-menu"
        aria-haspopup="true"
        variant="contained"
        style={{position:'absolute',right:'10px'}}
      >
        <Select
          value={language} // State to track selected language
          onChange={(e) => handleLanguageChange(e)}
          sx={{
            color: "white",
            "& .MuiSelect-icon": {
              color:  "white" , // Adjust icon color for dropdown
            },
          }}
        >
          <MenuItem value="en">English</MenuItem>
          <MenuItem value="pt">Portuguese</MenuItem>
        </Select>
      </IconButton>
      <img src="/newlogo3.png" style={{ width: "30vw", marginLeft: "33vw", marginBottom: "-25vh" }} />
      <IllustrationLayout
        title={t('Sign In')}
        description={t('Enter your email and password to sign in')}
      >
        <ToastContainer />
        <ArgonBox component="form" role="form" onSubmit={handleSubmit}>
          <ArgonBox mb={2}>
            <ArgonInput
              type="email"
              placeholder={t('Email')}
              size="large"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </ArgonBox>
          <ArgonBox mb={2}>
            <ArgonInput
              type={showPassword ? "text" : "password"}
              placeholder={t('Password')}
              size="large"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                !showPassword ? (
                  <VisibilityOutlinedIcon
                    style={{ height: "25px", width: "25px" }}
                    onClick={() => setShowPassword(true)}
                  />
                ) : (
                  <VisibilityOffOutlinedIcon
                    style={{ height: "25px", width: "25px" }}
                    onClick={() => setShowPassword(false)}
                  />
                )
              }
            />
          </ArgonBox>
          <ArgonBox mt={4} mb={1}>
            <ArgonButton
              color="info"
              size="large"
              fullWidth
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t("Sign In")
              )}
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
      </IllustrationLayout>
    </div>
  );
}

export default Illustration;
