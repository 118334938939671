/**
=========================================================
* Argon Dashboard 2 MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.
  Once you add a new route on this file it will be visible automatically on
  the Sidenav.
  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Argon Dashboard 2 MUI layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Jobs from "layouts/tables/jobs";
import View from "layouts/tables/view";
import Edit from "layouts/tables/edit";
import Clients from 'layouts/clients';
import Setting from "layouts/setting";
import OuterLink from "layouts/ApproverLink";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import { useTranslation } from 'react-i18next';


// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
const user  =JSON.parse(localStorage.getItem('user'))
const userEmail = process.env.REACT_APP_USER_EMAIL;

  // console.log("userEmail",userEmail)
const routes = [
  {
    type: "route",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-tv-2" />,
    component: <Dashboard />,
  },
  {
    type: "route",
    name: "Jobs",
    key: "jobs",
    route: "/jobs",
    icon: (
      <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-briefcase-24" />
    ),
    component: <Jobs />,
  },
  {
    type: "route",
    name: "Clients",
    key: "clients",
    route: "/clients",
    icon: <ArgonBox component="i" color="success" fontSize="14px" className="ni ni-single-02" />,
    component: <Clients />,
  },
  {
    type: "route",
    name: "Setting",
    key: "setting",
    route: "/setting",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-settings-gear-65" />,
    component: <Setting />,
  },
  user?.email===userEmail&&{
    type: "route",
    name: "Sign Up",
    key: "signup",
    route: "/sign-up",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-collection" />,
    component: <SignUp />,
  },
  {
    name: "Setting",
    route: "/CreateJobs",
    component: <Tables />,
  },
  {
    name: "View",
    route: "/view",
    component: <View />,
  },
  {
    name: "Edit",
    route: "/job_edit",
    component: <Edit />,
  },
  {
    type: "route",
    name: "Sign In",
    key: "sign-in",
    route: "/authentication/sign-in",
    icon: (
      <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-single-copy-04" />
    ),
    component: <SignIn />,
  },

  {
    name: "Approver Link",
    route: "/approver-link/:jobId",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-collection" />,
    component: <OuterLink />,
  },
];

export default routes;
