/**
=========================================================
* Argon Dashboard 2 MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";
// @mui material components
import {
  Box,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  DialogActions,
  Card,
  Menu,
  MenuItem,
  IconButton,
  capitalize,
} from "@mui/material";
import { DialogContentText } from "@mui/material";

import React from "react";
// import Grid from '@mui/material/Grid';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
// import {   TableContainer } from '@mui/material';

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { BASE_URL } from "endpoint";
// Layout page components
import BaseLayout from "layouts/setting/components/BaseLayout";
import CircularProgress from "@mui/material/CircularProgress";
import ArgonTypography from "components/ArgonTypography";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import EnhancedTable from "./EnhancedTable";
import FullFeaturedCrudGrid from "./EnhancedTable";
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;

const MenuButton = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t, i18n } = useTranslation();


  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <span style={{ float: "right", position: "relative", top: "-10px" }}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            props?.editTeam();
            handleClose();
          }}
        >
          {t('Edit')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            props?.deleteTeam();
            handleClose();
          }}
        >
          {t('Delete')}
        </MenuItem>
      </Menu>
    </span>
  );
};

function Billing() {
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [teamsDetails, setTeamDetails] = useState([]);
  const [teamName, setTeamName] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [open, setOpen] = useState(false);
  const [connectedNumber, setConnectedNumber] = useState("");
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState("");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false); // Manage delete dialog visibility
  const [deleteId, setDeleteId] = useState(null); // Track the team to be deleted
  const { t, i18n } = useTranslation();

  const getQrcode = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const email = localStorage.getItem("email");
      const response = await fetch(
        `${BASE_URL}wbm/generate-qr/${userDetails?.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`, // Set bearer token in the header
          },
        }
      );
      if (response.ok) {
        const result = await response.json();
        if (result?.msg === "Client already connected") {
          checkConnection();
        } else {
          setQrCode(result);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // toast.error("An error occurred. Please try again.");
    } finally {
      // Hide loader
      setLoading(false);
    }
  };

  const checkConnection = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const email = localStorage.getItem("email");
      const response = await fetch(
        `${BASE_URL}wbm/connected-number/${userDetails?.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        const result = await response.json();
        setConnectedNumber(result.connectedNumber);

        // After connection, call another API from server
        // await callServerApi();
      }
    } catch (error) {
      console.error("Error fetching connected number:", error);
    } finally {
      setLoading(false);
    }
  };

  const createTeam = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const email = localStorage.getItem("email");
      const response = await fetch(`${BASE_URL}teams/createTeam`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: teamName }),
      });
      if (response.ok) {
        const result = await response.json();
        handleClose();
        getTeams();
      }
    } catch (error) {
      // toast.error("An error occurred. Please try again.");
    } finally {
      // Hide loader
      setLoading(false);
    }
  };
  const getTeams = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${BASE_URL}teams/getTeams`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const result = await response.json();
        setTeamDetails(result);
      }
    } catch (error) {
      // toast.error("An error occurred. Please try again.");
    } finally {
      // Hide loader
      setLoading(false);
    }
  };
  const editTeam = async (id) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      // const email = localStorage.getItem("email");
      const response = await fetch(`${BASE_URL}teams/updateTeam/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: teamName }),
      });
      if (response.ok) {
        handleClose();
        setEditId("");
        getTeams();
      }
    } catch (error) {
      console.error(error);
    } finally {
      // Hide loader
      setLoading(false);
    }
  };

  const handleConfirmDelete = async (id) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${BASE_URL}teams/deleteTeam/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response.ok) {
        const result = await response.text();
        toast.success(t("Deleted team successfully!"));
        getTeams();
        setIsDeleteOpen(false); // Close the dialog after successful delete
      } else {
        toast.error(t("An error occurred. Please try again."));
      }
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    const userDetails = localStorage.getItem("user");
    if (userDetails) {
      setUserDetails(JSON.parse(userDetails));
    }
    getTeams();
  }, []);

  const handleClose = () => {
    setTeamName("");
    setOpen(false);
  };

  return (
    <BaseLayout
      stickyNavbar
      tabValue={tabValue}
      handleSetTabValue={handleSetTabValue}
    >
      <ArgonBox mt={2}>
        {tabValue === 0 ? (
          <>
            <Grid container spacing={3} style={{ position: "relative" }}>
              <Grid item xs={12}>
                <Card
                  style={{
                    margin: "10px",
                    boxShadow: "5px 5px 10px 0px lightgrey",
                    borderRadius: "5px",
                    padding: "8rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "start",
                    color: "black",
                  }}
                >
                  <h4>
                    {t('Name')} :-{" "}
                    {userDetails?.username
                      ? capitalize(userDetails?.username)
                      : ""}
                  </h4>
                  <br />
                  <h4>{t('Email')} :- {userDetails?.email}</h4>
                  <br />
                  <h4>{t('Mobile')}:- {userDetails?.mobileNo}</h4>
                  <br />
                  <h4>
                     {t('Company Name')} :-{" "}
                    {userDetails?.companyName
                      ? capitalize(userDetails?.companyName)
                      : ""}
                  </h4>
                </Card>
              </Grid>
            </Grid>
          </>
        ) : tabValue === 1 ? (
          <>
            {loading && (
              <Box
                sx={{
                  position: "fixed",
                  height: "100vh",
                  width: "100%",
                  background: "#00000040",
                  display: "flex",
                  top: "0",
                  left: "0",
                  zIndex: 999999,
                }}
              >
                <CircularProgress style={{ margin: "auto" }} />
              </Box>
            )}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {connectedNumber ? (
                  <>
                    <div
                      style={{
                        height: "250px",
                        background: "green",
                        position: "relative",
                        borderRadius: "5px",
                        padding: "15px",
                        color: "white",
                      }} // Render HTML content
                    >
                      <h6>{connectedNumber}</h6>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      height: "250px",
                      background: "green",
                      position: "relative",
                      borderRadius: "5px",
                      padding: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="info"
                      style={{ float: "right", color: "black" }}
                      // onClick={() => getQrcode()}
                      onClick={() => {}}
                      // startIcon={<AddIcon style={{ fontWeight: "bolder" }} />}
                    >
                      {t('Connect whatsapp')}
                    </Button>
                    {qrCode?.qrCode ? (
                      <img
                        src={qrCode?.qrCode}
                        style={{ height: "225px", width: "230px" }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          </>
        ) : tabValue === 2 ? (
          <>
            {loading && (
              <Box
                sx={{
                  position: "fixed",
                  height: "100vh",
                  width: "100%",
                  background: "#00000040",
                  display: "flex",
                  top: "0",
                  left: "0",
                  zIndex: 999999,
                }}
              >
                <CircularProgress style={{ margin: "auto" }} />
              </Box>
            )}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="info"
                  style={{ float: "right", color: "black" }}
                  onClick={() => setOpen(true)}
                  startIcon={
                    <AddIcon style={{ fontWeight: "bolder", color: "black" }} />
                  }
                >
                   {t('Create Team')}
                </Button>
              </Grid>
              {teamsDetails?.map((item, index) => {
                return (
                  <Grid item xs={3}>
                    <Card
                      key={index}
                      style={{
                        margin: "10px",
                        boxShadow: "0px 0px 7px 0px lightgrey",
                        borderRadius: "5px",
                        padding: "2rem",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "35px",
                          marginBottom: "-15px",
                          marginTop: "5px",
                        }}
                      >
                        <Groups2OutlinedIcon
                          style={{
                            fontWeight: "bolder",
                            position: "relative",
                            right: "-15px",
                            color: "red",
                          }}
                        />
                        <MenuButton
                          id={item?.id}
                          deleteTeam={() => {
                            setDeleteId(item?.id); // Set the team ID to be deleted
                            setIsDeleteOpen(true); // Open the delete confirmation dialog
                          }}
                          editTeam={() => {
                            setTeamName(item?.name);
                            setEditId(item?.id);
                            setIsEdit(true);
                            setOpen(true);
                          }}
                        />
                      </p>
                      <ArgonTypography
                        display="inline"
                        variant="p"
                        textTransform="capitalize"
                        px={2}
                        style={{
                          fontSize: "22px",
                          fontWeight: "600",
                          textAlign: "center",
                        }}
                      >
                        {item?.name}
                      </ArgonTypography>
                      <ArgonTypography
                        display="inline"
                        variant="p"
                        textTransform="capitalize"
                        px={2}
                        pb={3}
                        style={{
                          fontSize: "15px",
                          color: "grey",
                          fontWeight: "400",
                          textAlign: "center",
                        }}
                      >
                        {item?.customerCount} {t('users assiged')}
                      </ArgonTypography>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
            <Dialog
              open={isDeleteOpen}
              onClose={() => setIsDeleteOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {t("Confirm Delete")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                 
                  {t('Are you sure you want to delete this team? This action cannot be undone.')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsDeleteOpen(false)} color="primary">
                  {t('Cancel')}
                </Button>
                <Button
                  onClick={() => handleConfirmDelete(deleteId)}
                  color="secondary"
                  autoFocus
                  style={{color:"red"}}
                >
                  {t('Delete')}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : (
          <></>
        )}
      </ArgonBox>
      <Dialog
        open={open}
        onClose={() => {
          if (isEdit) {
            setIsEdit(false);
            handleClose();
          } else {
            handleClose();
          }
        }}
        fullWidth
        className="client"
      >
        <DialogTitle>{isEdit ? "Update" : "Create"} {t('Team')}</DialogTitle>
        <DialogContent>
          <ArgonBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={1}
            pt={1}
          >
            <FormControl fullWidth>
              <label htmlFor="jobName">{t('Team name')}</label>
              <input
                type="text"
                id="teamName"
                name={t('Team name')}
                placeholder={t('Team name')}
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
              />
            </FormControl>
          </ArgonBox>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "red" }}
            onClick={() => {
              if (isEdit) {
                setIsEdit(false);
                handleClose();
              } else {
                handleClose();
              }
            }}
          >
            {t('Cancel')}
          </Button>
          <Button
            onClick={() => {
              if (isEdit) {
                editTeam(editId);
              } else {
                createTeam();
              }
            }}
          >
            {" "}
            {isEdit ? "Update" : "Create"}{" "}
          </Button>
        </DialogActions>
      </Dialog>
      {tabValue === 3 ? (
        <>
          {loading && (
            <Box
              sx={{
                position: "fixed",
                height: "100vh",
                width: "100%",
                background: "#00000040",
                display: "flex",
                top: "0",
                left: "0",
                zIndex: 999999,
              }}
            >
              <CircularProgress style={{ margin: "auto" }} />
            </Box>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TableContainer
                style={{ transform: "translate(0px, 20px)" }}
                component={Paper}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  {/* <EnhancedTable/> */}
                  <FullFeaturedCrudGrid />
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </>
      ) : null}
    </BaseLayout>
  );
}

export default Billing;

// stop
