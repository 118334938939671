import React, { useState, useEffect } from "react";
import {
  Select,
  Menu,
  MenuItem,
  Autocomplete,
  TextField,
  IconButton,
  Typography,
  Box,
  Button,
  Card,
  Grid,
  colors,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import ArgonBox from "components/ArgonBox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { styled } from "@mui/material/styles";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { BASE_URL } from "endpoint";
import ArgonTypography from "components/ArgonTypography";
import moment from "moment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;

const MenuButton = (props) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <span style={{ float: "right" }}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/view", { state: { jobId: props?.id,userName:props?.userName } });
            handleClose();
          }}
        >
          {t('View')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/job_edit", { state: { jobId: props?.id } });
            handleClose();
          }}
        >
          {t('Edit')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            props?.deleteJob(props?.id);
            handleClose();
          }}
        >
          {t('Delete')}
        </MenuItem>
      </Menu>
    </span>
  );
};

function Tables() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [draft, setDraft] = useState([]);
  const location = useLocation();
  const clientDetails = location.state?.clientDetails;
  const [approvalWating, setApprovalWating] = useState([]);
  const [clientsData, setClientsData] = useState([]);
  const [filterClientId, setFilterClientId] = useState(null);
  const [filterClientDetails, setFilterClientDetails] = useState('');
  const [filterOptions, setFilterOptions] = useState([]);
  const [adjustment, setAdjustment] = useState([]);
  const [approved, setApproved] = useState([]);

  const { t, i18n } = useTranslation();


  const deleteJob = async (jobId) => {
    try {
      const token = localStorage.getItem("token"); // Get token from local storage
      const response = await fetch(`${BASE_URL}jobs/deleteJob/${jobId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`, // Set bearer token in the header
        },
      });

      if (response.ok) {
        const result = await response.text(); 
        getJobs();
        toast.success("Job deleted succesfuly.");
        // Handle success (e.g., show a message or update the UI)
      } else {
        console.error("Failed to delete job:", await response.text());
        // Handle error
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error
    }
  };

  const getJobs = async (clientId) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token"); // Get token from local storage
      const response = await fetch(clientId ? `${BASE_URL}jobs/getJobs/${clientId}` : `${BASE_URL}jobs/getJobs`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, // Set bearer token in the header
        },
      });

      if (response.ok) {
        const result = await response.json(); // Or `response.json()` if the server responds with JSON

        // const draftData = result?.filter((item) => item?.status === "Draft");
        const watingApproval = result?.filter(
          (item) => item?.status === "approval_pending"
        );
        const finalApproved = result?.filter(
          (item) => item?.status === "approved"
        );
        const finalListAdjustment = result?.filter(
          (item) => item?.status === "adjustment"
        );

        // if (draftData?.length > 0) {
        //   setDraft(draftData);
        // }
        if (watingApproval?.length > 0) {
          setApprovalWating(watingApproval);
        }
        if (finalApproved?.length > 0) {
          setApproved(finalApproved);
        }
        if (finalListAdjustment?.length > 0) {
          setAdjustment(finalListAdjustment);
        }
      } else {
        // toast.error("Failed to create job. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      // Hide loader
      setLoading(false);
    }
  };
  const getClients = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token"); // Get token from local storage
      const response = await fetch(`${BASE_URL}jobs/getAllCustomerInfo`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,// Set bearer token in the header

        },
      });

      if (response.ok) {
        const result = await response.json(); // Or `response.json()` if the server responds with JSON

        const options = result?.map((item) => {
          return {
            label: item?.customerName,
            value: item?.id,
            image: BASE_URL + item?.logo
          }
        })
        setClientsData(result)
        setFilterOptions(options)
      } else {
        // toast.error("Failed to fetch data.");
      }
    } catch (error) {
      toast.error("An error occurred.");
    } finally {
      // Hide loader
      setLoading(false);
    }
  };

  useEffect(() => {
    getClients()
  }, [])
  useEffect(() => {
    if (clientDetails) {

      setFilterClientId({
            label: clientDetails?.customerName,
            value: clientDetails?.id,
            image: BASE_URL + clientDetails?.logo
          })
      // getJobs(clientId);
    } else {
      getJobs();
    }
  }, [clientDetails]);


  useEffect(()=>{
    if(filterClientId){
      getJobs(filterClientId?.value);
    } else {
      getJobs();
    }
  },[filterClientId])







  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer />
      <ArgonBox py={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              style={{
                background: "#ffffff",
                color: "#66748E",
                float: "right",
              }}
              onClick={() => navigate("/createjobs")}
              startIcon={<AddIcon style={{ fontWeight: "bolder" }} />}
            >
              {t('Create Jobs')}
            </Button>
            <Autocomplete
              disablePortal
              options={filterOptions}
              sx={{
                width: 250,
                float: 'right',
                marginRight: '10px',
                '& .MuiInputBase-input': {
                  border: 'none !important',
                  boxShadow: 'none !important',
                  display:'inline-block !important',
                  width:'70% !important'
                },
              }}
              value={filterClientId}
              onChange={(e, val) => setFilterClientId(val ? val : null)} 
              renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: false }}   
              placeholder={!filterClientId ? 'Select client' : ''}
              InputProps={{
                ...params.InputProps,
                startAdornment: params.inputProps.value!==null ? (
            
                  <Box display="inline-block" alignItems="center">
                   {filterOptions.find(
                          (option) => option.label === params.inputProps.value
                        )?.image&& <img
                      src={
                        filterOptions.find(
                          (option) => option.label === params.inputProps.value
                        )?.image
                      }
                      alt={params.inputProps.value}
                      style={{ width: 30, height: 30, marginRight: 10 }}
                    />}
                  </Box>
                ) :null,
              }} />}
              renderOption={(props, option) => (
                <Box component="li" {...props} display="flex" alignItems="center">
                  <img
                    src={option.image}
                    alt={option.label}
                    style={{ width: 30, height: 30, marginRight: 10 }}
                  />
                  <Typography style={{fontSize:"12px"}}>{option.label}</Typography>
                </Box>
              )}
            />
          </Grid>
          {/* <Grid item xs={3}>
            <ArgonBox mb={3}>
              <Card style={{ borderRadius: "5px", padding: "5px" }}>
                <ArgonTypography
                  display="inline"
                  variant="h5"
                  textTransform="capitalize"
                  style={{ textAlign: "center", marginBottom: "25px" }}
                >
                  ({draft?.length}) Draft
                </ArgonTypography>

                {draft?.map((item, index) => {
                  return (
                    <Card
                      key={index}
                      style={{
                        margin: "10px",
                        boxShadow: "0px 0px 7px 0px lightgrey",
                      }}
                    >
                      <ArgonTypography
                        display="inline"
                        variant="p"
                        textTransform="capitalize"
                        px={2}
                        pt={3}
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        {item?.jobName}
                      </ArgonTypography>
                      <ArgonTypography
                        display="inline"
                        variant="p"
                        px={3}
                        textTransform="capitalize"
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                          color: "grey",
                        }}
                      >
                        {item?.createdAt
                          ? moment(item?.createdAt).format("mm/DD/yyyy, hh:mm")
                          : ""}
                        <CalendarTodayIcon
                          style={{
                            position: "relative",
                            top: "2px",
                            left: "2px",
                          }}
                        />
                      </ArgonTypography>
                      <ArgonBox pb={1} pt={2}>
                        <ArgonTypography
                          display="inline"
                          variant="span"
                          px={3}
                          textTransform="capitalize"
                          style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "#5594deab",
                          }}
                        >
                           {item?.item?item?.item:0} {t('items')}
                        </ArgonTypography>
                        <MenuButton id={item?.id} deleteJob={deleteJob} />
                      </ArgonBox>
                    </Card>
                  );
                })}
              </Card>
            </ArgonBox>
          </Grid> */}
          <Grid item xs={4}>
            <ArgonBox mb={3}>
              <Card style={{ borderRadius: "5px", padding: "5px" }}>
                <ArgonTypography
                  display="inline"
                  variant="h5"
                  textTransform="capitalize"
                  style={{ textAlign: "center", marginBottom: "25px" }}
                >
                  ({approvalWating?.length}) {t('Waiting for approval')}
                </ArgonTypography>

                {approvalWating?.map((item, index) => {
                  return (
                    <Card
                      key={index}
                      style={{
                        margin: "10px",
                        boxShadow: "0px 0px 7px 0px lightgrey",
                      }}
                    >
                      <ArgonTypography
                        display="inline"
                        variant="p"
                        textTransform="capitalize"
                        px={2}
                        pt={3}
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        {item?.jobName}
                      </ArgonTypography>
                      <ArgonTypography
                        display="inline"
                        variant="p"
                        px={3}
                        textTransform="capitalize"
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "grey",
                          textAlign: "right",
                        }}
                      >
                        {item?.createdAt
                          ? moment(item?.createdAt).format("mm/DD/yyyy, hh:mm")
                          : ""}
                        <CalendarTodayIcon
                          style={{
                            position: "relative",
                            top: "2px",
                            left: "2px",
                          }}
                        />
                      </ArgonTypography>
                      <ArgonBox pb={1} pt={2}>
                        <ArgonTypography
                          display="inline"
                          variant="span"
                          px={3}
                          textTransform="capitalize"
                          style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "#5594deab",
                          }}
                        >
                           {item?.item?item?.item:0} {t('items')}
                        </ArgonTypography>
                        <MenuButton id={item?.id} deleteJob={deleteJob} 
                        userName={clientsData?.filter((i)=>i?.id===Number(item?.client))?.[0]?.levels?.[0]?.approvers?.[0]?.name} />
                      </ArgonBox>
                    </Card>
                  );
                })}
              </Card>
            </ArgonBox>
          </Grid>
          <Grid item xs={4}>
            <ArgonBox mb={3}>
              <Card style={{ borderRadius: "5px", padding: "5px" }}>
                <ArgonTypography
                  display="inline"
                  variant="h5"
                  textTransform="capitalize"
                  style={{ textAlign: "center", marginBottom: "25px" }}
                >
                  ({adjustment?.length}) {t('Adjustment request')}
                </ArgonTypography>

                {adjustment?.map((item, index) => {
                  return (
                    <Card
                      key={index}
                      style={{
                        margin: "10px",
                        boxShadow: "0px 0px 7px 0px lightgrey",
                      }}
                    >
                      <ArgonTypography
                        display="inline"
                        variant="p"
                        textTransform="capitalize"
                        px={2}
                        pt={3}
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        {item?.jobName}
                      </ArgonTypography>
                      <ArgonTypography
                        display="inline"
                        variant="p"
                        px={3}
                        textTransform="capitalize"
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "grey",
                          textAlign: "right",
                        }}
                      >
                        {item?.createdAt
                          ? moment(item?.createdAt).format("mm/DD/yyyy, hh:mm")
                          : ""}
                        <CalendarTodayIcon
                          style={{
                            position: "relative",
                            top: "2px",
                            left: "2px",
                          }}
                        />
                      </ArgonTypography>
                      <ArgonBox pb={1} pt={2}>
                        <ArgonTypography
                          display="inline"
                          variant="span"
                          px={3}
                          textTransform="capitalize"
                          style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "#5594deab",
                          }}
                        >
                           {item?.item?item?.item:0} {t('items')}
                        </ArgonTypography>
                        <MenuButton id={item?.id} deleteJob={deleteJob} />
                      </ArgonBox>
                    </Card>
                  );
                })}
              </Card>
            </ArgonBox>
          </Grid>
          <Grid item xs={4}>
            <ArgonBox mb={3}>
              <Card style={{ borderRadius: "5px", padding: "5px" }}>
                <ArgonTypography
                  display="inline"
                  variant="h5"
                  textTransform="capitalize"
                  style={{ textAlign: "center", marginBottom: "25px" }}
                >
                  ({approved?.length}) {t('Approved')}
                </ArgonTypography>

                {approved?.map((item, index) => {
                  return (
                    <Card
                      key={index}
                      style={{
                        margin: "10px",
                        boxShadow: "0px 0px 7px 0px lightgrey",
                      }}
                    >
                      <ArgonTypography
                        display="inline"
                        variant="p"
                        textTransform="capitalize"
                        px={2}
                        pt={3}
                        style={{ fontSize: "14px", fontWeight: "600" }}
                      >
                        {item?.jobName}
                      </ArgonTypography>
                      <ArgonTypography
                        display="inline"
                        variant="p"
                        px={3}
                        textTransform="capitalize"
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "grey",
                          textAlign: "right",
                        }}
                      >
                        {item?.createdAt
                          ? moment(item?.createdAt).format("mm/DD/yyyy, hh:mm")
                          : ""}
                        <CalendarTodayIcon
                          style={{
                            position: "relative",
                            top: "2px",
                            left: "2px",
                          }}
                        />
                      </ArgonTypography>
                      <ArgonBox pb={1} pt={2}>
                        <ArgonTypography
                          display="inline"
                          variant="span"
                          px={3}
                          textTransform="capitalize"
                          style={{
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "#5594deab",
                          }}
                        >
                          {item?.item?item?.item:0} {t('items')}
                        </ArgonTypography>
                        <MenuButton id={item?.id} deleteJob={deleteJob} />
                      </ArgonBox>
                    </Card>
                  );
                })}
              </Card>
            </ArgonBox>
          </Grid>
        </Grid>
      </ArgonBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Tables;
