import React, { useState } from "react";
import { Menu, MenuItem, Button } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const platformColors = {
  Facebook: "#4267B2",
  Instagram: "#C13584",
  LinkedIn: "#0077B5",
};

const Index = (props) => {
  const { formData, index, handleMenuItemClick,isEdit } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getIcon = (platform) => {
    switch (platform) {
      case "Facebook":
        return <FacebookIcon style={{ color: "white" }} />;
      case "Instagram":
        return <InstagramIcon style={{ color: "white" }} />;
      case "LinkedIn":
        return <LinkedInIcon style={{ color: "white" }} />;
      default:
        return null;
    }
  };

  // Check if the platform is already selected
  const isPlatformSelected = (platform) => {
    return isEdit?formData[index]?.platform?.split(",")?.includes(platform):formData[index]?.platform.includes(platform);
  };

  // Toggle platform selection
  const togglePlatformSelection = (platform) => {
    let selectedPlatforms = (isEdit?formData[index]?.platform?.split(","):formData[index]?.platform) || [];

    if (selectedPlatforms.includes(platform)) {
      // If platform is already selected, remove it
      selectedPlatforms = selectedPlatforms.filter(
        (selected) => selected !== platform
      );
    } else {
      // Otherwise, add the platform to the array
      selectedPlatforms = [...selectedPlatforms, platform];
    }
    // Update the formData with the selected platforms
    handleMenuItemClick(index, selectedPlatforms);
  };

  return (
    <div style={{ padding: "10px" }}>
      <Button
        aria-controls={`media-menu`}
        aria-haspopup="true"
        onClick={(e) => handleClick(e)}
        style={{
          backgroundColor:
          isEdit? platformColors[formData[index]?.platform?.split(",")?.[0]]: platformColors[formData[index]?.platform?.[0]] || "#ccc",
          color: "white",
          borderRadius: "8px",
          display: "flex",
          width: "100%",
          alignItems: "center",
        }}
        endIcon={
          <div style={{ display: "flex", gap: "5px" }}>
            {isEdit?formData[index]?.platform?.split(",")?.map((platform) => getIcon(platform)):formData[index]?.platform?.map((platform) => getIcon(platform))}
          </div>
        }
      >
        {formData[index]?.platform?.length
          ? ''
          : "Select Platforms"}
      </Button>
      <Menu
        id={`media-menu`}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ width: "100%" }}
      >
        <MenuItem
          onClick={() => {
            togglePlatformSelection("Facebook");
          }}
        >
          <FacebookIcon
            style={{
              marginRight: "10px",
              color: platformColors.Facebook,
            }}
          />
          Facebook {isPlatformSelected("Facebook") && "(Selected)"}
        </MenuItem>
        <MenuItem
          onClick={() => {
            togglePlatformSelection("Instagram");
          }}
        >
          <InstagramIcon
            style={{
              marginRight: "10px",
              color: platformColors.Instagram,
            }}
          />
          Instagram {isPlatformSelected("Instagram") && "(Selected)"}
        </MenuItem>
        <MenuItem
          onClick={() => {
            togglePlatformSelection("LinkedIn");
          }}
        >
          <LinkedInIcon
            style={{
              marginRight: "10px",
              color: platformColors.LinkedIn,
            }}
          />
          LinkedIn {isPlatformSelected("LinkedIn") && "(Selected)"}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Index;
