import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormControl } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout/user";
import CircularProgress from "@mui/material/CircularProgress";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "endpoint";
import { useTranslation } from 'react-i18next';


const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg";

function Illustration() {
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [countryCode, setCounteryCode] = useState("+1");
  const [mobileNo, setMobileNo] = useState();
  const [cpfcnpj, setCpfCnpj] = useState();
  const [loading, setLoading] = useState(false);
  const [alignment, setAlignment] = useState("personal");
  const [collaborators, setCollaborators] = useState("1");
  const [ssections, setSsections] = useState(false);
  const { t, i18n } = useTranslation();


  const reset = () => {
    setCompanyName('')
    setUsername('')
    setEmail('')
    setPassword('')
    setCounteryCode("+1")
    setCollaborators("1")
    setAlignment("personal")
    setMobileNo("")
    setCpfCnpj("")
  }

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleChangeCollaborators = (event, newAlignment) => {
    setCollaborators(newAlignment);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const userData = {
      companyName,
      username,
      email,
      password,
      collaborators,
      account: alignment,
      countryCode,
      mobileNo,
      cpfcnpj,
    };

    try {
      const response = await fetch(`${BASE_URL}signup`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (response.ok) {
        toast.success(t("User created successfully!"));
        reset()
        setSsections(false) // Wait for 2 seconds before navigating
      } else {
        toast.error(t('An error occurred. Please try again.'));
      }
    } catch (error) {
      toast.error(t('An error occurred. Please try again.'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout >
      <DashboardNavbar />
      <ToastContainer  />
      <IllustrationLayout 
        title={t('User Details')}
        description=""
        className="new-css"
        illustration={{
          image: bgImage,
          title: t('Attention is the new currency'),
          description:
            t("The more effortless the writing looks, the more effort the writer actually put into the process."),
        }}
        
      >
        <ToastContainer />
        <ArgonBox  component="form" role="form" onSubmit={handleSubmit}>
          {!ssections ? (
            <>
              <ArgonBox mb={2}>
                <ArgonInput
                
                  type="text"
                  placeholder={t("Company/professional name")}
                  size="large"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </ArgonBox>
              <ArgonBox mb={2}>
                <FormControl  fullWidth>
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                  >
                    <ToggleButton value="personal" style={{ width: "50%" }}>
                       {t('Personal account')}
                    </ToggleButton>
                    <ToggleButton value="business" style={{ width: "50%" }}>
                    {t('Business account')} 
                    </ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
              </ArgonBox>

              <ArgonBox mb={2}>
                <FormControl fullWidth>
                  <label htmlFor="mobileNumber"> {t('Number of collaborators')}</label>
                  <ToggleButtonGroup
                    color="primary"
                    value={collaborators}
                    exclusive
                    onChange={handleChangeCollaborators}
                    aria-label="Platform"
                  >
                    <ToggleButton value="1" style={{ width: "25%" }}>
                      1
                    </ToggleButton>
                    <ToggleButton value="2-10" style={{ width: "25%" }}>
                      2-10
                    </ToggleButton>
                    <ToggleButton value="11-50" style={{ width: "25%" }}>
                      11-50
                    </ToggleButton>
                    <ToggleButton value="50+" style={{ width: "25%" }} x>
                      50+
                    </ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
              </ArgonBox>

              <ArgonBox mt={4} mb={1}>
                <ArgonButton
                  color="info"
                  size="large"
                  fullWidth
                  onClick={() => setSsections(true)}
                  disabled={companyName == ""}
                >
                  {t('Next')}
                </ArgonButton>
              </ArgonBox>
            </>
          ) : (
            <>
              <ArgonBox mb={2}>
                <ArgonInput
                  type="email"
                  placeholder={t("Email")}
                  size="large"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </ArgonBox>

              <ArgonBox mb={2}>
                <ArgonInput
                  type="text"
                  placeholder={t("Administrator Name")}
                  size="large"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </ArgonBox>

              <ArgonBox mb={2}>
                <ArgonInput
                  type="text"
                  placeholder="CPF/CNPJ"
                  size="large"
                  value={cpfcnpj}
                  onChange={(e) => setCpfCnpj(e.target.value)}
                />
              </ArgonBox>

              <ArgonBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pt={1}
                pb={2}
                className="client"
              >
                <FormControl fullWidth>
                  <label htmlFor="mobileNumber">{t('Mobile Number')}</label>
                  <ArgonBox display="flex" alignItems="center">
                    <select
                      id="countryCode"
                      name="countryCode"
                      style={{
                        marginRight: "8px",
                        width: "auto",
                        height: "40px",
                      }}
                      value={countryCode}
                      onChange={(e) => setCounteryCode(e.target.value)}
                    >
                      <option value="+1">+1 (US)</option>
                      <option value="+44">+44 (UK)</option>
                      <option value="+91">+91 (India)</option>
                      <option value="+351">+351 (Portugal)</option>
                      <option value="+93">+93 (Afghanistan)</option>
                      <option value="+355">+355 (Albania)</option>
                      <option value="+213">+213 (Algeria)</option>
                      <option value="+1684">+1684 (American Samoa)</option>
                      <option value="+376">+376 (Andorra)</option>
                      <option value="+244">+244 (Angola)</option>
                      <option value="+1264">+1264 (Anguilla)</option>
                      <option value="+672">+672 (Antarctica)</option>
                      <option value="+1268">+1268 (Antigua and Barbuda)</option>
                      <option value="+54">+54 (Argentina)</option>
                      <option value="+374">+374 (Armenia)</option>
                      <option value="+297">+297 (Aruba)</option>
                      <option value="+61">+61 (Australia)</option>
                      <option value="+43">+43 (Austria)</option>
                      <option value="+994">+994 (Azerbaijan)</option>
                      <option value="+1242">+1242 (Bahamas)</option>
                      <option value="+973">+973 (Bahrain)</option>
                      <option value="+880">+880 (Bangladesh)</option>
                      <option value="+1246">+1246 (Barbados)</option>
                      <option value="+375">+375 (Belarus)</option>
                      <option value="+32">+32 (Belgium)</option>
                      <option value="+501">+501 (Belize)</option>
                      <option value="+229">+229 (Benin)</option>
                      <option value="+1441">+1441 (Bermuda)</option>
                      <option value="+975">+975 (Bhutan)</option>
                      <option value="+591">+591 (Bolivia)</option>
                      <option value="+387">+387 (Bosnia and Herzegovina)</option>
                      <option value="+267">+267 (Botswana)</option>
                      <option value="+55">+55 (Brazil)</option>
                      <option value="+246">
                        +246 (British Indian Ocean Territory)
                      </option>
                      <option value="+1284">
                        +1284 (British Virgin Islands)
                      </option>
                      <option value="+673">+673 (Brunei)</option>
                      <option value="+359">+359 (Bulgaria)</option>
                      <option value="+226">+226 (Burkina Faso)</option>
                      <option value="+257">+257 (Burundi)</option>
                      <option value="+855">+855 (Cambodia)</option>
                      <option value="+237">+237 (Cameroon)</option>
                      <option value="+1">+1 (Canada)</option>
                      <option value="+238">+238 (Cape Verde)</option>
                      <option value="+1345">+1345 (Cayman Islands)</option>
                      <option value="+236">
                        +236 (Central African Republic)
                      </option>
                      <option value="+235">+235 (Chad)</option>
                      <option value="+56">+56 (Chile)</option>
                      <option value="+86">+86 (China)</option>
                      <option value="+61">+61 (Christmas Island)</option>
                      <option value="+61">+61 (Cocos [Keeling] Islands)</option>
                      <option value="+57">+57 (Colombia)</option>
                      <option value="+269">+269 (Comoros)</option>
                      <option value="+243">+243 (Congo [DRC])</option>
                      <option value="+242">+242 (Congo [Republic])</option>
                      <option value="+682">+682 (Cook Islands)</option>
                      <option value="+506">+506 (Costa Rica)</option>
                      <option value="+225">+225 (Côte d'Ivoire)</option>
                      <option value="+385">+385 (Croatia)</option>
                      <option value="+53">+53 (Cuba)</option>
                      <option value="+599">+599 (Curaçao)</option>
                      <option value="+357">+357 (Cyprus)</option>
                      <option value="+420">+420 (Czech Republic)</option>
                      <option value="+45">+45 (Denmark)</option>
                      <option value="+253">+253 (Djibouti)</option>
                      <option value="+1767">+1767 (Dominica)</option>
                      <option value="+1809">+1809 (Dominican Republic)</option>
                      <option value="+593">+593 (Ecuador)</option>
                      <option value="+20">+20 (Egypt)</option>
                      <option value="+503">+503 (El Salvador)</option>
                      <option value="+240">+240 (Equatorial Guinea)</option>
                      <option value="+291">+291 (Eritrea)</option>
                      <option value="+372">+372 (Estonia)</option>
                      <option value="+251">+251 (Ethiopia)</option>
                      <option value="+500">
                        +500 (Falkland Islands [Islas Malvinas])
                      </option>
                      <option value="+298">+298 (Faroe Islands)</option>
                      <option value="+679">+679 (Fiji)</option>
                      <option value="+358">+358 (Finland)</option>
                      <option value="+33">+33 (France)</option>
                      <option value="+594">+594 (French Guiana)</option>
                      <option value="+689">+689 (French Polynesia)</option>
                      <option value="+241">+241 (Gabon)</option>
                      <option value="+220">+220 (Gambia)</option>
                      <option value="+995">+995 (Georgia)</option>
                      <option value="+49">+49 (Germany)</option>
                      <option value="+233">+233 (Ghana)</option>
                      <option value="+350">+350 (Gibraltar)</option>
                      <option value="+30">+30 (Greece)</option>
                      <option value="+299">+299 (Greenland)</option>
                      <option value="+1473">+1473 (Grenada)</option>
                      <option value="+590">+590 (Guadeloupe)</option>
                      <option value="+1671">+1671 (Guam)</option>
                      <option value="+502">+502 (Guatemala)</option>
                      <option value="+44">+44 (Guernsey)</option>
                      <option value="+224">+224 (Guinea)</option>
                      <option value="+245">+245 (Guinea-Bissau)</option>
                      <option value="+592">+592 (Guyana)</option>
                      <option value="+509">+509 (Haiti)</option>
                      <option value="+504">+504 (Honduras)</option>
                      <option value="+852">+852 (Hong Kong SAR China)</option>
                      <option value="+36">+36 (Hungary)</option>
                      <option value="+354">+354 (Iceland)</option>
                      <option value="+91">+91 (India)</option>
                      <option value="+62">+62 (Indonesia)</option>
                      <option value="+98">+98 (Iran)</option>
                      <option value="+964">+964 (Iraq)</option>
                      <option value="+353">+353 (Ireland)</option>
                      <option value="+44">+44 (Isle of Man)</option>
                      <option value="+972">+972 (Israel)</option>
                      <option value="+39">+39 (Italy)</option>
                      <option value="+1876">+1876 (Jamaica)</option>
                      <option value="+81">+81 (Japan)</option>
                      <option value="+44">+44 (Jersey)</option>
                      <option value="+962">+962 (Jordan)</option>
                      <option value="+7">+7 (Kazakhstan)</option>
                      <option value="+254">+254 (Kenya)</option>
                      <option value="+686">+686 (Kiribati)</option>
                      <option value="+965">+965 (Kuwait)</option>
                      <option value="+996">+996 (Kyrgyzstan)</option>
                      <option value="+856">+856 (Laos)</option>
                      <option value="+371">+371 (Latvia)</option>
                      <option value="+961">+961 (Lebanon)</option>
                      <option value="+266">+266 (Lesotho)</option>
                      <option value="+231">+231 (Liberia)</option>
                      <option value="+218">+218 (Libya)</option>
                      <option value="+423">+423 (Liechtenstein)</option>
                      <option value="+370">+370 (Lithuania)</option>
                      <option value="+352">+352 (Luxembourg)</option>
                      <option value="+853">+853 (Macau SAR China)</option>
                      <option value="+389">+389 (Macedonia)</option>
                      <option value="+261">+261 (Madagascar)</option>
                      <option value="+265">+265 (Malawi)</option>
                      <option value="+60">+60 (Malaysia)</option>
                      <option value="+960">+960 (Maldives)</option>
                      <option value="+223">+223 (Mali)</option>
                      <option value="+356">+356 (Malta)</option>
                      <option value="+692">+692 (Marshall Islands)</option>
                      <option value="+596">+596 (Martinique)</option>
                      <option value="+222">+222 (Mauritania)</option>
                      <option value="+230">+230 (Mauritius)</option>
                      <option value="+262">+262 (Mayotte)</option>
                      <option value="+52">+52 (Mexico)</option>
                      <option value="+691">+691 (Micronesia)</option>
                      <option value="+373">+373 (Moldova)</option>
                      <option value="+377">+377 (Monaco)</option>
                      <option value="+976">+976 (Mongolia)</option>
                      <option value="+382">+382 (Montenegro)</option>
                      <option value="+1664">+1664 (Montserrat)</option>
                      <option value="+212">+212 (Morocco)</option>
                      <option value="+258">+258 (Mozambique)</option>
                      <option value="+95">+95 (Myanmar [Burma])</option>
                      {/* Add more country codes as needed */}
                    </select>

                    <input
                      type="number"
                      id="mobileNumber"
                      name={t('Mobile Number')}
                      placeholder={t('Enter Mobile Number')}
                      value={mobileNo}
                      onChange={(e) => setMobileNo(e.target.value)}
                      style={{
                        flex: 1,
                        border: "solid 1px #d4d3d2",
                        borderRadius: "5px",
                        height: "41px",
                        paddingLeft: "10px",
                      }}
                    />
                  </ArgonBox>
                </FormControl>
              </ArgonBox>

              <ArgonBox mb={2}>
                <ArgonInput
                  type="password"
                  placeholder={t('Password')}
                  size="large"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </ArgonBox>

              <ArgonBox mt={4} mb={1}>
                <ArgonButton
                  style={{ marginRight: "10px" }}
                  color="error"
                  size="large"
                  onClick={() => setSsections(false)}
                >
                 {t('Back')}
                </ArgonButton>
                <ArgonButton
                  color="info"
                  size="large"
                  type="submit"
                  disabled={
                    loading ||
                    email == "" ||
                    mobileNo == null ||
                    mobileNo == "" ||
                    password == ""
                  }
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    t("Create User")
                  )}
                </ArgonButton>
              </ArgonBox>
            </>
          )}

          {/* <ArgonBox mt={3} textAlign="center">
            <ArgonTypography variant="button" color="text" fontWeight="regular">
              Already have an account?{" "}
              <ArgonTypography
                component={Link}
                to="/authentication/sign-in"
                variant="button"
                color="info"
                fontWeight="medium"
              >
                Sign in
              </ArgonTypography>
            </ArgonTypography>
          </ArgonBox> */}
        </ArgonBox>
      </IllustrationLayout>
    </DashboardLayout>
  );
}

export default Illustration;
