// Calendar.js
import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "./calendar.css";
import { BASE_URL } from "endpoint";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const localizer = momentLocalizer(moment);
// import { useTranslation } from 'react-i18next';


const MyCalendar = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  // const { t, i18n } = useTranslation();




  const getJobs = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token"); // Get token from local storage
      const response = await fetch(`${BASE_URL}jobs/getJobsWithItems`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, // Set bearer token in the header
        },
      });

      if (response.ok) {
        const result = await response.json(); // Or `response.json()` if the server responds with JSON
        const eventData = [];

        result?.map((item) => {

          item?.jobItems?.map((_item) => {
            const start = _item?.publicationDate
            ? moment(_item?.publicationDate).startOf('hour') // Round down to nearest hour
            : null;
          const end = start
            ? start.clone().add(1, 'hour') // Add 1 hour to the start time
            : null;

              eventData.push({
                title: `${_item?.title} - ${item?.jobName}`,
                start: start ? start.toDate() : '', // Convert to JS Date object
                end: end ? end.toDate() : '',
                id: item?.jobId,           // Job ID
                // allDay: true,  // You can uncomment if you want to treat it as an all-day event
              });
          })

        })
        setEvents(eventData)
      } else {
        // toast.error("Failed to fetch jobs. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      // Hide loader
      setLoading(false);
    }
  };


  useEffect(() => {
    getJobs();
  }, []);


  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        onSelectEvent={(i) => { navigate("/view", { state: { jobId: i?.id } }); }}
        endAccessor="end"
        style={{ height: 500 }}
        views={['month', 'week', 'day']}
      />
    </div>
  );
};

export default MyCalendar;
