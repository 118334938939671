// MultiLevelDialog.js
import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    IconButton,
    Grid,
    Box,
} from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import { BASE_URL } from "endpoint";
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from "react-toastify";
import "./jobs.css";
import { useTranslation } from 'react-i18next';


const MultiLevelDialog = ({ open, handleClose, customerId, setLoading, levelsData,getData }) => {
    const [levels, setLevels] = useState([{ levelName: '', approvers: [{ name: '', email: '', countryCode: '', mobileNo: '' }] }]);
 const [isEdit, setIsEdit] = useState(false);
 const { t, i18n } = useTranslation();
    useEffect(() => {
        if (levelsData?.length > 0) {
            setLevels(levelsData)
            setIsEdit(true)
        }
    }, [levelsData])

    // Function to add a new level
    function addLevel() {
        setLevels([...levels, { levelName: '', approvers: [{ name: '', email: '', countryCode: '', mobileNo: '' }] }]);
    }

    // Function to remove a level
    const removeLevel = (levelIndex) => {
        setLevels(levels.filter((_, index) => index !== levelIndex));
    };

    // Function to add a new approver to a level
    const addApprover = (levelIndex) => {
        const newLevels = [...levels];
        newLevels[levelIndex].approvers.push({ name: '', email: '', countryCode: '', mobileNo: '' });
        setLevels(newLevels);
    };

    // Function to remove an approver from a level
    const removeApprover = (levelIndex, approverIndex) => {
        const newLevels = [...levels];
        newLevels[levelIndex].approvers = newLevels[levelIndex].approvers.filter((_, index) => index !== approverIndex);
        setLevels(newLevels);
    };

    // Function to handle change in input fields
    const handleInputChange = (levelIndex, approverIndex, field, value) => {
        const newLevels = [...levels];
        newLevels[levelIndex].approvers[approverIndex][field] = value;
        setLevels(newLevels);
    };

    const handleLevelNameChange = (levelIndex, value) => {
        const newLevels = [...levels];
        newLevels[levelIndex].levelName = value;
        setLevels(newLevels);
    };

    const createApprover = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem("token"); // Get token from local storage
            const response = await fetch(isEdit?`${BASE_URL}jobs/updateLevelsAndApprovers`:`${BASE_URL}jobs/addLevelsAndApprovers`, {
                method: isEdit?"PUT":"POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    customerId,
                    levels
                }),
            });

            if (response.ok) {
                const result = await response.text(); // Or `response.json()` if the server responds with JSON
                toast.success(t("Client created successfully!"));
                getData()
                setLevels([])
                handleClose()
                // Handle success (e.g., show a message or redirect)
            } else {
                toast.error(t("Failed to create customer info!"));
                // Handle error
            }
        } catch (error) {
            toast.error(t("An error occurred. Please try again."));
            // Handle error
        } finally {
            // Hide loader
            setLoading(false);
        }
    };


    return (<>

        <Dialog open={open} onClose={() => {
            setIsEdit(false)
            handleClose()
        }} maxWidth="md" fullWidth>
            <DialogTitle>{t('Manage Levels and Approvers')}</DialogTitle>
            <DialogContent>
                {levels.map((level, levelIndex) => (
                    <Box key={levelIndex} sx={{ marginBottom: 2, padding: 2, border: '1px solid #ddd', borderRadius: 2 }}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12}>
                                <TextField
                                    label={`Level ${levelIndex + 1}`}
                                    value={level.levelName}
                                    onChange={(e) => handleLevelNameChange(levelIndex, e.target.value)}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true, // Ensures the label stays fixed
                                    }}
                                    placeholder={t('Name')}
                                    sx={{
                                        "& .MuiFormLabel-root": {
                                            fontSize: '15px',
                                            top: "2px"
                                        }
                                    }}
                                />
                            </Grid>
                            {/* <Grid item xs={2}>
                <IconButton onClick={() => removeLevel(levelIndex)}>
                  <Remove />
                </IconButton>
              </Grid> */}
                        </Grid>
                        {level.approvers.map((approver, approverIndex) => (
                            <Grid container spacing={2} alignItems="center" key={approverIndex} mt={3}>
                                <Grid item xs={6}>
                                    <TextField
                                        label={t('Name')}
                                        value={approver.name}
                                        onChange={(e) => handleInputChange(levelIndex, approverIndex, 'name', e.target.value)}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true, // Ensures the label stays fixed
                                        }}
                                        placeholder={t('Name')}
                                        sx={{
                                            "& .MuiFormLabel-root": {
                                                fontSize: '15px',
                                                top: "2px"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        label={t('Email')}
                                        value={approver.email}
                                        onChange={(e) => handleInputChange(levelIndex, approverIndex, 'email', e.target.value)}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true, // Ensures the label stays fixed
                                        }}
                                        placeholder={t('Email')}
                                        sx={{
                                            "& .MuiFormLabel-root": {
                                                fontSize: '15px',
                                                top: "2px"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>

                                    {/* <TextField
                                        label="Country Code"
                                        value={approver.countryCode}
                                        onChange={(e) => handleInputChange(levelIndex, approverIndex, 'countryCode', e.target.value)}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true, // Ensures the label stays fixed
                                        }}
                                        placeholder='Country Code'
                                        sx={{
                                            "& .MuiFormLabel-root": {
                                                fontSize: '15px',
                                                top: "2px"
                                            }
                                        }}
                                    /> */}
                                        <select
                    id="countryCode"
                    name={t('countryCode')}
                    style={{
                      marginRight: "8px",
                      width: "190px",
                      height: "40px",
                    }}
                    value={approver.countryCode}
                    onChange={(e) => handleInputChange(levelIndex, approverIndex, 'countryCode', e.target.value)}
                  >
                    <option value="+1">+1 (US)</option>
                    <option value="+44">+44 (UK)</option>
                    <option value="+91">+91 (India)</option>
                    <option value="+351">+351 (Portugal)</option>
                    <option value="+93">+93 (Afghanistan)</option>
                    <option value="+355">+355 (Albania)</option>
                    <option value="+213">+213 (Algeria)</option>
                    <option value="+1684">+1684 (American Samoa)</option>
                    <option value="+376">+376 (Andorra)</option>
                    <option value="+244">+244 (Angola)</option>
                    <option value="+1264">+1264 (Anguilla)</option>
                    <option value="+672">+672 (Antarctica)</option>
                    <option value="+1268">+1268 (Antigua and Barbuda)</option>
                    <option value="+54">+54 (Argentina)</option>
                    <option value="+374">+374 (Armenia)</option>
                    <option value="+297">+297 (Aruba)</option>
                    <option value="+61">+61 (Australia)</option>
                    <option value="+43">+43 (Austria)</option>
                    <option value="+994">+994 (Azerbaijan)</option>
                    <option value="+1242">+1242 (Bahamas)</option>
                    <option value="+973">+973 (Bahrain)</option>
                    <option value="+880">+880 (Bangladesh)</option>
                    <option value="+1246">+1246 (Barbados)</option>
                    <option value="+375">+375 (Belarus)</option>
                    <option value="+32">+32 (Belgium)</option>
                    <option value="+501">+501 (Belize)</option>
                    <option value="+229">+229 (Benin)</option>
                    <option value="+1441">+1441 (Bermuda)</option>
                    <option value="+975">+975 (Bhutan)</option>
                    <option value="+591">+591 (Bolivia)</option>
                    <option value="+387">+387 (Bosnia and Herzegovina)</option>
                    <option value="+267">+267 (Botswana)</option>
                    <option value="+55">+55 (Brazil)</option>
                    <option value="+246">
                      +246 (British Indian Ocean Territory)
                    </option>
                    <option value="+1284">
                      +1284 (British Virgin Islands)
                    </option>
                    <option value="+673">+673 (Brunei)</option>
                    <option value="+359">+359 (Bulgaria)</option>
                    <option value="+226">+226 (Burkina Faso)</option>
                    <option value="+257">+257 (Burundi)</option>
                    <option value="+855">+855 (Cambodia)</option>
                    <option value="+237">+237 (Cameroon)</option>
                    <option value="+1">+1 (Canada)</option>
                    <option value="+238">+238 (Cape Verde)</option>
                    <option value="+1345">+1345 (Cayman Islands)</option>
                    <option value="+236">
                      +236 (Central African Republic)
                    </option>
                    <option value="+235">+235 (Chad)</option>
                    <option value="+56">+56 (Chile)</option>
                    <option value="+86">+86 (China)</option>
                    <option value="+61">+61 (Christmas Island)</option>
                    <option value="+61">+61 (Cocos [Keeling] Islands)</option>
                    <option value="+57">+57 (Colombia)</option>
                    <option value="+269">+269 (Comoros)</option>
                    <option value="+243">+243 (Congo [DRC])</option>
                    <option value="+242">+242 (Congo [Republic])</option>
                    <option value="+682">+682 (Cook Islands)</option>
                    <option value="+506">+506 (Costa Rica)</option>
                    <option value="+225">+225 (Côte d'Ivoire)</option>
                    <option value="+385">+385 (Croatia)</option>
                    <option value="+53">+53 (Cuba)</option>
                    <option value="+599">+599 (Curaçao)</option>
                    <option value="+357">+357 (Cyprus)</option>
                    <option value="+420">+420 (Czech Republic)</option>
                    <option value="+45">+45 (Denmark)</option>
                    <option value="+253">+253 (Djibouti)</option>
                    <option value="+1767">+1767 (Dominica)</option>
                    <option value="+1809">+1809 (Dominican Republic)</option>
                    <option value="+593">+593 (Ecuador)</option>
                    <option value="+20">+20 (Egypt)</option>
                    <option value="+503">+503 (El Salvador)</option>
                    <option value="+240">+240 (Equatorial Guinea)</option>
                    <option value="+291">+291 (Eritrea)</option>
                    <option value="+372">+372 (Estonia)</option>
                    <option value="+251">+251 (Ethiopia)</option>
                    <option value="+500">
                      +500 (Falkland Islands [Islas Malvinas])
                    </option>
                    <option value="+298">+298 (Faroe Islands)</option>
                    <option value="+679">+679 (Fiji)</option>
                    <option value="+358">+358 (Finland)</option>
                    <option value="+33">+33 (France)</option>
                    <option value="+594">+594 (French Guiana)</option>
                    <option value="+689">+689 (French Polynesia)</option>
                    <option value="+241">+241 (Gabon)</option>
                    <option value="+220">+220 (Gambia)</option>
                    <option value="+995">+995 (Georgia)</option>
                    <option value="+49">+49 (Germany)</option>
                    <option value="+233">+233 (Ghana)</option>
                    <option value="+350">+350 (Gibraltar)</option>
                    <option value="+30">+30 (Greece)</option>
                    <option value="+299">+299 (Greenland)</option>
                    <option value="+1473">+1473 (Grenada)</option>
                    <option value="+590">+590 (Guadeloupe)</option>
                    <option value="+1671">+1671 (Guam)</option>
                    <option value="+502">+502 (Guatemala)</option>
                    <option value="+44">+44 (Guernsey)</option>
                    <option value="+224">+224 (Guinea)</option>
                    <option value="+245">+245 (Guinea-Bissau)</option>
                    <option value="+592">+592 (Guyana)</option>
                    <option value="+509">+509 (Haiti)</option>
                    <option value="+504">+504 (Honduras)</option>
                    <option value="+852">+852 (Hong Kong SAR China)</option>
                    <option value="+36">+36 (Hungary)</option>
                    <option value="+354">+354 (Iceland)</option>
                    <option value="+91">+91 (India)</option>
                    <option value="+62">+62 (Indonesia)</option>
                    <option value="+98">+98 (Iran)</option>
                    <option value="+964">+964 (Iraq)</option>
                    <option value="+353">+353 (Ireland)</option>
                    <option value="+44">+44 (Isle of Man)</option>
                    <option value="+972">+972 (Israel)</option>
                    <option value="+39">+39 (Italy)</option>
                    <option value="+1876">+1876 (Jamaica)</option>
                    <option value="+81">+81 (Japan)</option>
                    <option value="+44">+44 (Jersey)</option>
                    <option value="+962">+962 (Jordan)</option>
                    <option value="+7">+7 (Kazakhstan)</option>
                    <option value="+254">+254 (Kenya)</option>
                    <option value="+686">+686 (Kiribati)</option>
                    <option value="+965">+965 (Kuwait)</option>
                    <option value="+996">+996 (Kyrgyzstan)</option>
                    <option value="+856">+856 (Laos)</option>
                    <option value="+371">+371 (Latvia)</option>
                    <option value="+961">+961 (Lebanon)</option>
                    <option value="+266">+266 (Lesotho)</option>
                    <option value="+231">+231 (Liberia)</option>
                    <option value="+218">+218 (Libya)</option>
                    <option value="+423">+423 (Liechtenstein)</option>
                    <option value="+370">+370 (Lithuania)</option>
                    <option value="+352">+352 (Luxembourg)</option>
                    <option value="+853">+853 (Macau SAR China)</option>
                    <option value="+389">+389 (Macedonia)</option>
                    <option value="+261">+261 (Madagascar)</option>
                    <option value="+265">+265 (Malawi)</option>
                    <option value="+60">+60 (Malaysia)</option>
                    <option value="+960">+960 (Maldives)</option>
                    <option value="+223">+223 (Mali)</option>
                    <option value="+356">+356 (Malta)</option>
                    <option value="+692">+692 (Marshall Islands)</option>
                    <option value="+596">+596 (Martinique)</option>
                    <option value="+222">+222 (Mauritania)</option>
                    <option value="+230">+230 (Mauritius)</option>
                    <option value="+262">+262 (Mayotte)</option>
                    <option value="+52">+52 (Mexico)</option>
                    <option value="+691">+691 (Micronesia)</option>
                    <option value="+373">+373 (Moldova)</option>
                    <option value="+377">+377 (Monaco)</option>
                    <option value="+976">+976 (Mongolia)</option>
                    <option value="+382">+382 (Montenegro)</option>
                    <option value="+1664">+1664 (Montserrat)</option>
                    <option value="+212">+212 (Morocco)</option>
                    <option value="+258">+258 (Mozambique)</option>
                    <option value="+95">+95 (Myanmar [Burma])</option>
                    {/* Add more country codes as needed */}
                  </select>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        label={t('Mobile Number')}
                                        value={approver.mobileNo}
                                        onChange={(e) => handleInputChange(levelIndex, approverIndex, 'mobileNo', e.target.value)}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true, // Ensures the label stays fixed
                                        }}
                                        placeholder={t('Mobile Number')}
                                        sx={{
                                            "& .MuiFormLabel-root": {
                                                fontSize: '15px',
                                                top: "2px"
                                            }
                                        }}
                                    />
                                </Grid>
                                {/* <Grid item xs={2}>
                                    <IconButton onClick={() => removeApprover(levelIndex, approverIndex)}>
                                        <DeleteIcon style={{ color: 'red' }} />
                                    </IconButton>
                                </Grid> */}
                            </Grid>
                        ))}
                        {/* <Button onClick={() => addApprover(levelIndex)} startIcon={<Add />} sx={{ marginTop: 1 }}>
                            Add Approver
                        </Button> */}
                    </Box>
                ))}
                {/* <Button onClick={addLevel} startIcon={<Add />}>
                    Add Level
                </Button> */}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setIsEdit(false)
                    handleClose()
                }}>{t('Cancel')}</Button>
                <Button onClick={() => createApprover()} variant="contained" color="primary">
                    {t('Save')}
                </Button>
            </DialogActions>
        </Dialog>
    </>
    );
};

export default MultiLevelDialog;
