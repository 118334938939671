import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "endpoint";
import { useTranslation } from 'react-i18next';

import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';

const initialRows = []; // Will be set from API

function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;
  const { t, i18n } = useTranslation();

  const handleClick = () => {
    const id = Math.random().toString(36).substr(2, 9); // Random ID generator
    setRows((oldRows) => [
      ...oldRows,
      { id, companyName:'',username: '', email: '', number: '', isNew: true },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary"  startIcon={<AddIcon />} onClick={handleClick}>
        {t('Add record')}
      </Button>
    </GridToolbarContainer>
  );
}

export default function FullFeaturedCrudGrid() {
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [open, setOpen] = React.useState(false); // Dialog state
  const [deleteId, setDeleteId] = React.useState(null); 
  const { t, i18n } = useTranslation();// Track row to delete

  const getuser = async () => {
    try {
      const token = localStorage.getItem("token"); // Retrieve token from local storage
      const response = await fetch(`${BASE_URL}users`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, // Pass token in the header
        },
      });

      if (response.ok) {
        const result = await response.json();

        const formattedRows = result.map(user => ({
          id: user.id,
          companyName: user.companyName || '',
          username: user.username || '',
          email: user.email || '',
          number: user.mobileNo || '',
        }));
        setRows(formattedRows);
      } else {
        toast.error(t("An error occurred. Please try again."));
      }
    } catch (error) {
      toast.error(t("An error occurred. Please try again."));
    }
  };

  React.useEffect(() => {
    getuser();
  }, []);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setDeleteId(id); // Set the id of the row to delete
    setOpen(true);    // Open the confirmation dialog
  };

  const deleteUser = async (id) => {
    try {
      const token = localStorage.getItem("token"); // Retrieve token from local storage
      const response = await fetch(`${BASE_URL}deleteSignup/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`, // Pass token in the header
        },
      });

      if (response.ok) {
        toast.success(t("Client updated successfully!"));
        getuser()
        // setRows(rows.filter((row) => row.id !== id)); // Remove the row from the state
      } else {
        toast.error(t("An error occurred. Please try again."));
      }
    } catch (error) {
      toast.error(t("An error occurred. Please try again."));
    }
  };

  // Confirm deletion after dialog
  const handleConfirmDelete = () => {
    deleteUser(deleteId); // Call delete API when user confirms
    setOpen(false);  // Close the dialog
  };

  const handleCancelDelete = () => {
    setOpen(false);  // Close the dialog without deleting
    setDeleteId(null);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    try {
      const token = localStorage.getItem("token"); // Get token from local storage
      const response = await fetch(`${BASE_URL}updateSignup/${newRow?.id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`, // Set bearer token in the header
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
         companyName:newRow?.companyName,
         username:newRow?.username,
         email:newRow?.email,
         mobileNo:newRow?.number,
    }),
      });

      if (response.ok) {
        const result = await response.text(); // Or `response.json()` if the server responds with JSON
        toast.success(t("Client updated successfully!"));
        getuser()
        // Handle success (e.g., show a message or redirect)
      } else {
        console.error("Failed to create customer info:", await response.text());
       toast.error(t("An error occurred. Please try again."));
        // Handle error
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error(t("An error occurred. Please try again."));
      // Handle error
      }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: 'companyName', headerName: 'Company Name', width: 220, editable: true, },
    { field: 'username', headerName: 'User Name', width: 220, editable: true, },
    {
      field: 'email',
      headerName: 'Email',
      type: 'email',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'number',
      headerName: 'Number',
      type: 'string',
      width: 180,
      editable: true,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)} // Trigger delete with confirmation
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 500,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
      />

      {/* Confirmation Dialog */}
      <Dialog
        open={open}
        onClose={handleCancelDelete}
      >
        <DialogTitle>{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Are you sure you want to delete this row?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            {t('Cancel')}
          </Button>
          <Button style={{color:"red"}} onClick={handleConfirmDelete} color="primary" autoFocus>
            {t('Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
