import React, { useState, useEffect } from "react";
import { Button, Card, Grid } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import AccessTimeFilledOutlinedIcon from "@mui/icons-material/AccessTimeFilledOutlined";
import ArgonBox from "components/ArgonBox";
import CheckIcon from "@mui/icons-material/Check";
import "react-datepicker/dist/react-datepicker.css";
import DashboardLayout from "examples/LayoutContainers/PageLayout";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "endpoint";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../tables/jobs.css";
import ArgonTypography from "components/ArgonTypography";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

const platformColors = {
  Facebook: "#4267B2",
  Instagram: "#C13584",
  LinkedIn: "#0077B5",
};

const ITEM_HEIGHT = 48;

function OuterLink() {
  const [loading, setLoading] = useState(false);
  const [statusUpdated, setStatusUpdated] = useState(false);
  const [jobDetails, setJobDetails] = useState({});
  const [imagesDetails, setImagesDetails] = useState([]);
  const [postDetails, setPostDetails] = useState({});
  const [adjustmentText, setAdjustmentText] = useState(" ");
  const [open, setOpen] = React.useState(false);
  const { t, i18n } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { jobId } = useParams();

  const getJobs = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}jobs/getJobForLink/${id}`, {
        method: "GET",
      });

      if (response.ok) {
        const result = await response.json(); // Or `response.json()` if the server responds with JSON

        setJobDetails(result);
        setPostDetails(result?.jobItems?.[0]);
        const imageUrl =
          result?.jobItems?.[0]?.firstFiles?.map((item) => {
            const fullUrl = `${BASE_URL}${item}`;
            return { url: fullUrl };
          }) || [];
        setImagesDetails(imageUrl);
      } else {
        // toast.error('Failed to create job. Please try again.');
      }
    } catch (error) {
      toast.error(t("An error occurred. Please try again."));
        } finally {
      // Hide loader
      setLoading(false);
    }
  };

  useEffect(() => {
    getJobs(jobId?.split("_")[0]);
  }, [jobId]);

  const getIcon = (platform) => {
    switch (platform) {
      case "Facebook":
        return (
          <FacebookIcon
            style={{ color: platformColors.Facebook, height: 40, width: 40 }}
          />
        );
      case "Instagram":
        return (
          <InstagramIcon
            style={{ color: platformColors.Instagram, height: 40, width: 40 }}
          />
        );
      case "LinkedIn":
        return (
          <LinkedInIcon
            style={{ color: platformColors.LinkedIn, height: 40, width: 40 }}
          />
        );
      default:
        return null;
    }
  };

  const submitForApproval = async (status) => {
    setLoading(true);

    try {
      const response = await fetch(
        `${BASE_URL}jobs/updateStatusForLink/${jobId?.split("_")[0]}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            status,
          }),
        }
      );
      if (response.ok) {
        const result = await response.text(); // Or `response.json()` if the server responds with JSON
        // toast.success('Status updated successfully!');
        setStatusUpdated(true);
        getJobs(jobId?.split("_")?.[0]);
        // Clear form or redirect as needed
      } else {
        toast.error(t("An error occurred. Please try again."));
      }
    } catch (error) {
      toast.error(t("An error occurred. Please try again."));
    } finally {
      // Hide loader
      setLoading(false);
    }
  };

  useEffect(() => {
    if (jobDetails && jobDetails.jobItems) {
      const allApproved = jobDetails.jobItems.every(
        (item) => item.status === "approved"
      );
      if (allApproved && !statusUpdated) {
        // Check if status hasn't been updated
        submitForApproval("approved"); // Call function if all job items are approved
      }
    }
  }, [jobDetails, statusUpdated]);
  const approvedItem = async (type) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${BASE_URL}jobs/updateJobItemForLink/${postDetails?.id}`,
        {
          method: "PUT",
          headers: {
            // Set bearer token in the header
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            status: type === "adjustment" ? "adjustment" : "approved",
            adjustment_comment: adjustmentText,
            username: jobId?.split("_")[1],
          }),
        }
      );
      if (response.ok) {
        const result = await response.text(); // Or `response.json()` if the server responds with JSON
        if (type === "adjustment") {
          submitForApproval("adjustment");
          handleClose();
        } else {
          toast.success(t("Status updated successfully!"));
          getJobs(jobId?.split("_")?.[0]);
        }

        // Clear form or redirect as needed
      } else {
        toast.error(t("An error occurred. Please try again."));
      }
    } catch (error) {
      toast.error(t("An error occurred. Please try again."));
    } finally {
      // Hide loader
      setLoading(false);
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <DashboardLayout>
      <ToastContainer />
      {jobDetails?.jobItems?.length > 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ArgonBox mb={3}>
              <Card
                style={{
                  borderRadius: "4px",
                  padding: "8px 15px",
                  minHeight: "90px",
                }}
              >
                <ArgonTypography
                  display="inline"
                  variant="h6"
                  textTransform="capitalize"
                  className="color-background"
                >
                  {jobDetails?.jobName}
                </ArgonTypography>
                <div style={{ marginTop: "5px" }}>
                  {jobDetails?.jobItems?.map((elem, index) => {
                    return (
                      <Button
                        key={index}
                        variant="outlined"
                        size="small"
                        sx={{
                          display: "inline-block",
                          borderColor:
                            elem?.status === "approved" ? "green" : "grey",
                          backgroundColor:
                            elem?.id === postDetails?.id
                              ? elem?.status === "approved"
                                ? "green"
                                : "lightgrey"
                              : "",
                          padding: "4px",
                          color: elem?.status === "approved" ? "white" : "grey",
                          marginRight: "10px",
                          fontSize: "15px",
                          border: "1px solid grey",

                          "& .MuiSvgIcon-root": {
                            fontSize: "18px !important",
                            position: "relative",
                            bottom: "-4px",
                          },
                          "& .MuiButton-root:focus:not(:hover)": {
                            boxShadow: "none",
                          },
                          "& .css-mdpcs1-MuiButtonBase-root-MuiButton-root:active":
                            {
                              boxShadow: "none",
                            },
                        }}
                        startIcon={
                          elem?.status === "approved" ? (
                            <CheckIcon />
                          ) : (
                            <AccessTimeFilledOutlinedIcon />
                          )
                        }
                        onClick={() => {
                          setPostDetails(elem);
                          const imageUrl =
                            elem?.firstFiles?.map((item) => {
                              const fullUrl = `${BASE_URL}${item}`;
                              return { url: fullUrl };
                            }) || [];
                          setImagesDetails(imageUrl);
                        }}
                      >
                        {index + 1}
                      </Button>
                    );
                  })}
                </div>
              </Card>
            </ArgonBox>
          </Grid>
          <Grid item xs={9}>
            <ArgonBox mb={3}>
              <Card
                style={{
                  borderRadius: "4px",
                  padding: "8px 15px",
                  minHeight: "90px",
                }}
              >
                <ArgonTypography
                  display="inline"
                  variant="h6"
                  textTransform="capitalize"
                  className="color-background"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                >
                  {postDetails?.platform
                    ?.split(",")
                    ?.map((platform) => getIcon(platform))}
                  {jobDetails?.jobName}
                </ArgonTypography>
                <ArgonTypography
                  display="inline"
                  variant="P"
                  textTransform="capitalize"
                  className="color-background"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                    fontSize: "14px",
                  }}
                >
                  {t("Date to publish")}:{" "}
                  {postDetails?.publicationDate
                    ? moment(postDetails?.publicationDate).format(
                        "DD/MM/YYYY HH:mm"
                      )
                    : ""}
                </ArgonTypography>
                {imagesDetails?.length > 0 && (
                  <Slider {...settings}>
                    {imagesDetails.map((image, index) => (
                      <div key={index}>
                        <img
                          src={image.url}
                          alt={`Slide ${index}`}
                          style={{ width: "100%", height: "auto" }}
                        />
                      </div>
                    ))}
                  </Slider>
                )}
                <ArgonTypography
                  display="inline"
                  variant="P"
                  textTransform="capitalize"
                  className="color-background"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                    fontSize: "24px",
                    fontWeight: "400",
                    paddingLeft: "18px",
                    color: "grey",
                  }}
                >
                  {t("Caption")}
                </ArgonTypography>
                <ArgonTypography
                  display="inline"
                  variant="P"
                  textTransform="capitalize"
                  className="color-background"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                    fontSize: "14px",
                    fontWeight: "100",
                    paddingLeft: "5px",
                    color: "grey",
                  }}
                >
                  {postDetails?.description}
                </ArgonTypography>
                {["approval_pending"].includes(jobDetails?.status) && (
                  <ArgonBox mb={3}>
                    <Button
                      variant="outlined"
                      size="small"
                      style={{ color: "#0ecdef", marginTop: "10px" }}
                      onClick={() => {
                        handleClickOpen();
                      }}
                    >
                      {t("Request adjustment")}
                    </Button>
                    {postDetails?.status !== "approved" && (
                      <Button
                        variant="contained\"
                        size="small"
                        disabled={postDetails?.status === "adjustment"}
                        style={{
                          background: "#40c057",
                          color: "white",
                          float: "right",
                          marginTop: "10px",
                        }}
                        onClick={() => approvedItem()}
                      >
                        {t("Approve item")}
                      </Button>
                    )}
                  </ArgonBox>
                )}
              </Card>
            </ArgonBox>
          </Grid>
          <Grid item xs={3}>
            <ArgonBox mb={3}>
              <Card
                style={{
                  borderRadius: "4px",
                  padding: "8px 15px",
                  minHeight: "90px",
                }}
              >
                <ArgonTypography
                  display="inline"
                  variant="h6"
                  textTransform="capitalize"
                  className="color-background"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                    fontSize: "20px",
                  }}
                >
                  {t("History")}
                </ArgonTypography>
                <hr style={{ borderColor: "lightgrey" }} />
                <p
                  style={{
                    fontSize: "13px",
                    color: "#0257cc",
                    marginTop: "5px",
                  }}
                >
                  {jobDetails?.createdAt
                    ? moment(jobDetails?.createdAt).format("DD/MM/YYYY hh:mm")
                    : ""}
                </p>
                <p
                  style={{ fontSize: "13px", color: "grey", marginTop: "5px" }}
                >
                  {t("It was registered")}
                </p>

                {postDetails?.adjustments?.map((item) => {
                  return (
                    <div style={{ marginTop: "20px" }}>
                      <p
                        style={{
                          fontSize: "13px",
                          color: "#0257cc",
                          marginTop: "5px",
                        }}
                      >
                        {item?.createdAt
                          ? `${moment(item?.createdAt).format(
                              "DD/MM/YYYY hh:mm"
                            )} by ${item?.username}`
                          : ""}
                      </p>
                      <p
                        style={{
                          fontSize: "13px",
                          color: "grey",
                          marginTop: "5px",
                        }}
                      >
                        {item?.comment}
                      </p>
                    </div>
                  );
                })}
              </Card>
            </ArgonBox>
          </Grid>
        </Grid>
      ) : (
        <h1 style={{ textAlign: "center", marginTop: "20%" }}>
          {t("Detais not found")}
        </h1>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: { width: "500px" },
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            approvedItem("adjustment");
          },
        }}
      >
        <DialogTitle>
          {" "}
          {t("Request adjustment of item")} {postDetails?.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <textarea
              id="description"
              name={t("Description")}
              onChange={(e) => setAdjustmentText(e.target.value)}
              placeholder={t("Description")}
              style={{
                width: "100%",
                height: "150px",
                padding: "10px",
                fontSize: "16px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                boxSizing: "border-box",
                fontFamily: "Arial, sans-serif",
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("Cancel")}</Button>
          <Button type="submit">{t("Submit")}</Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default OuterLink;
