import React, { useState, useEffect } from 'react';
import { Select, Menu, MenuItem, FormControl, InputLabel, IconButton, Typography, Box, Button, Card, Grid } from '@mui/material';
import emailjs from 'emailjs-com';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import ArgonBox from "components/ArgonBox";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { styled } from '@mui/material/styles';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { ToastContainer, toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import MediaButton from "./MediaSelect"
import "./jobs.css"
import { BASE_URL } from 'endpoint';
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';


const StyledCard = styled(Card)(({ theme }) => ({
  border: `2px dashed ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  textAlign: 'center',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const platformColors = {
  Facebook: '#4267B2',
  Instagram: '#C13584',
  LinkedIn: '#0077B5',
};

function Tables() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [clientOption, setClientOption] = useState([]);
  const [jobData, setJobData] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const [client, setClient] = useState('');
  const [jobName, setJobName] = useState('');
  const [files, setFiles] = useState([]);
  const [anchorEl, setAnchorEl] = useState({});
  const location = useLocation();
  const jobId = location.state.jobId;
  const { t, i18n } = useTranslation();
  const [items, setItems] = useState([
    {
      title: '',
      description: '',
      mediaType: '',
      publicationDate: null,
    },
  ]);
  const [formData, setFormData] = useState([{ title: '', description: '', mediaType: '', platform: 'Select Platform', publicationDate: null }]);

  const getClients = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token'); // Get token from local storage
      const response = await fetch(`${BASE_URL}jobs/getAllCustomerInfo`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}` // Set bearer token in the header
        },
      });

      if (response.ok) {
        const result = await response.json(); 
        setClientOption(result)

      } else {
        toast.error('Failed to fetch data.');
      }
    } catch (error) {
      toast.error('An error occurred.');
    } finally {
      // Hide loader
      setLoading(false);
    }
  }


  useEffect(() => {
    getClients()
  }, [])


  const getJobs = async (id) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token'); // Get token from local storage
      const response = await fetch(`${BASE_URL}jobs/getJob/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}` // Set bearer token in the header
        },
      });

      if (response.ok) {
        const result = await response.json(); 
        setJobData(result)
        setJobName(result?.jobName)
        setClient(result?.client)
        setSelectedDate(result?.approvalDeadline)
        const jobitemsDetails = result?.jobItems?.map((item, index) => {

          return {
            ...item,
            filesFirstSection: item?.firstFiles,
            filesSecondSection: item?.secondFiles
          }
        })
        const additionalItems = jobitemsDetails.map(() => ({
          title: '',
          description: '',
          mediaType: '',
          publicationDate: null,
        }));

        // Update state for items and formData
        setItems(additionalItems);
        setFormData(jobitemsDetails)
      } else {
        toast.error('Failed to fetch the details.');
      }
    } catch (error) {
      toast.error('An error occurred.');
    } finally {
      // Hide loader
      setLoading(false);
    }
  }

  useEffect(() => {
    getJobs(jobId)
  }, [jobId])

  const handleClose = (index) => {
    setAnchorEl(prev => ({ ...prev, [index]: null }));
  };

  const handleMenuItemClick = (index, selectedPlatforms) => {
    const updatedFormData = [...formData];
  
      updatedFormData[index].platform = selectedPlatforms.join(",");
  
  
    // Update the form data state
    setFormData(updatedFormData);
  };

  const getIcon = (platform) => {
    switch (platform) {
      case 'Facebook':
        return <FacebookIcon style={{ color: 'white' }} />;
      case 'Instagram':
        return <InstagramIcon style={{ color: 'white' }} />;
      case 'LinkedIn':
        return <LinkedInIcon style={{ color: 'white' }} />;
      default:
        return null;
    }
  };


  const handleRemoveFile = (fileName) => {
    setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
  };

  const deleteImages = async (fileType, imagePath) => {

    // Show loader
    setLoading(true);

    try {
      const token = localStorage.getItem('token'); // Get token from local storage

      const response = await fetch(`${BASE_URL}jobs/deleteImage/${jobId}/${fileType}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`, // Set bearer token in the header
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          imagePath: imagePath // Convert the body to a JSON string
        }),
      });
      if (response.ok) {
        const result = await response.text(); // Or `response.json()` if the server responds with JSON
        // toast.success('Image Deleted Successfully!');
        getJobs(jobId)
        // Clear form or redirect as needed
      } else {
        toast.error('Failed to delete image');
      }
    } catch (error) {
      toast.error('An error occurred.');
    } finally {
      // Hide loader
      setLoading(false);
    }
  };
  const renderFileThumbnails = (index) => (
    formData[index]?.filesFirstSection?.map(file => {

      if (typeof (file) === 'object') {
        return (

          <div key={file.name} style={{ position: 'relative', display: 'inline-block', margin: '10px' }}>
            <img
              src={URL.createObjectURL(file)}
              alt={file.name}
              style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '8px' }}
            />
            <IconButton
              style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                height: '25px',
                width: "25px",
                color: 'white',
                backgroundColor: 'black',
                padding: '5px',
              }}
              onClick={() => handleRemoveFile(index, file.name)}
            >
              <ClearIcon />
            </IconButton>
          </div>
        )
      } else {
        return <div key={file.name} style={{ position: 'relative', display: 'inline-block', margin: '10px' }}>
          <img
            src={`${BASE_URL}${file}`}
            alt={''}
            style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '8px' }}
          />
          <IconButton
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              height: '25px',
              width: "25px",
              color: 'white',
              backgroundColor: 'black',
              padding: '5px',
            }}
            onClick={() => deleteImages('firstFiles', file)}
          >
            <ClearIcon />
          </IconButton>
        </div>
      }
    })
  );

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    processFiles(droppedFiles);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };


  const processFiles = (newFiles, index) => {
    const filePreviews = [];
    const updatedFormData = [...formData];

    newFiles.forEach((file) => {
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          filePreviews.push({ file, src: reader.result });
          if (filePreviews.length === newFiles.length) {
            updatedFormData[index] = { ...updatedFormData[index], files: [...updatedFormData[index].files, ...filePreviews] };
            setFormData(updatedFormData);
          }
        };
        reader.readAsDataURL(file);
      }
    });
  };

  const handleRemoveFiledrag = (index) => {
    const updatedFormData = [...formData];
    updatedFormData[index] = { ...updatedFormData[index], filesSecondSection: updatedFormData[index].filesSecondSection.filter((_, i) => i !== index) }
    setFormData(updatedFormData);
    // setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };
  const handleFileSelectFirstSection = (index, event) => {
    const files = Array.from(event?.target?.files);
    const updatedFormData = [...formData];
    updatedFormData[index].filesFirstSection = [
      ...(updatedFormData[index]?.filesFirstSection || []),
      ...files,
    ];
    setFormData(updatedFormData);
  };

  // Function to handle file selection in the second upload section (conditional)
  const handleFileSelectSecondSection = (index, event) => {
    const files = Array.from(event.target.files);
    const updatedFormData = [...formData];
    updatedFormData[index].filesSecondSection = [
      ...(updatedFormData[index]?.filesSecondSection || []),
      ...files,
    ];;
    setFormData(updatedFormData);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedFormData = [...formData];
    updatedFormData[index] = { ...updatedFormData[index], [name]: value };
    setFormData(updatedFormData);
  };
  const formatDateForMySQL = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return date.toISOString().slice(0, 19).replace('T', ' ');
  };


  const prepareForApiCall = async () => {
    // Prepare file data as URLs or other relevant formats
    const formDataS = new FormData();

    // Append general job data
    formDataS.append('client', client || 'Unknown Client');
    formDataS.append('jobName', jobName || 'Untitled Job');
    formDataS.append('approvalDeadline', formatDateForMySQL(selectedDate));
    formDataS.append('status', 'approval_pending');

    // Append job items data
    formData?.forEach((elem, index) => {
      if (elem?.id) {
        formDataS.append(`jobItems[${index}][id]`, elem.id);
      }
      formDataS.append(`jobItems[${index}][title]`, elem.title || 'No Title');
      formDataS.append(`jobItems[${index}][description]`, elem.description || 'No Description');
      formDataS.append(`jobItems[${index}][publicationDate]`, formatDateForMySQL(selectedDate));
      formDataS.append(`jobItems[${index}][platform]`, elem.platform)
      formDataS.append(`jobItems[${index}][mediaType]`, (elem.platform === "Facebook" || elem.platform === "LinkedIn") ? 'post' : elem.mediaType ? elem.mediaType : 'Reels' || 'Unknown Media Type');
      // Append files to corresponding job item
      elem?.filesFirstSection?.forEach(file => {
        formDataS.append(`jobItems[${index}][filesFirstSection]`, file);
      });
      elem?.filesSecondSection?.forEach(file => {
        formDataS.append(`jobItems[${index}][filesSecondSection]`, file);
      });
    });
    for (var pair of formDataS.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }

    // Show loader
    const clientArr = clientOption?.filter((item) => item?.id === Number(client))
    const email = clientArr?.[0]?.levels?.[0]?.approvers?.[0]?.email;
    if(email){
      setLoading(true);

      try {
        const token = localStorage.getItem('token'); // Get token from local storage
        const response = await fetch(`${BASE_URL}jobs/editJob/${jobId}`, {
          method: 'PUT',
          headers: {
            'Authorization': `Bearer ${token}`, // Set bearer token in the header
          },
          body: formDataS,
        });
        if (response.ok) {
          const result = await response.text(); // Or `response.json()` if the server responds with JSON
          toast.success('Job updated successfully!');
          getJobs(jobId)
          navigate("/jobs");
          // Clear form or redirect as needed
        } else {
          toast.error('Failed to update job.');
        }
      } catch (error) {
        toast.error('An error occurred.');
      } finally {
        // Hide loader
        setLoading(false);
      }

    }else{ 
      toast.error("Selected client doesn't have the approver email");
    }

  };

  const submitForApproval = async () => {
    setLoading(true);

    try {
      const token = localStorage.getItem('token'); // Get token from local storage

      const response = await fetch(`${BASE_URL}jobs/updateStatus/${jobId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`, // Set bearer token in the header
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          status: "approval_pending"
        }),
      });
      if (response.ok) {
        const result = await response.text(); // Or `response.json()` if the server responds with JSON
        toast.success('Status updated successfully!');
        getJobs(jobId)
        navigate("/jobs");
        // sendMsg()
        sendEmail()
        // Clear form or redirect as needed
      } else {
        toast.error('Failed to update Status.');
      }
    } catch (error) {
      toast.error('An error occurred.');
    } finally {
      // Hide loader
      setLoading(false);
    }
  }

  const sendEmail = () => {
    // Construct your JSON payload
    const clientArr = clientOption?.filter((item) => item?.id === Number(client))
    const email = clientArr?.[0]?.levels?.[0]?.approvers?.[0]?.email;
    const name = clientArr?.[0]?.levels?.[0]?.approvers?.[0]?.name;
    if (email) {
      const emailData = {
        user_name: 'John Doe',
        user_email: email,
        message: `Welcome to innovStar  https://innov.sync.adatechpulse.com/approver-link/${jobId}_${name}`
      };

      // Use EmailJS to send email
      emailjs.send('service_6cfjx4o', 'template_zijjhmy', emailData, '7ed2SQaTqGSlhbB3v')
        .then((result) => {
          console.log('Email sent successfully:', result.text);
        }, (error) => {
          console.log('Failed to send email:', error.text);
        });
    }
  };




  const addItem = () => {
    setItems([
      ...items,
      {
        title: '',
        description: '',
        mediaType: '',
        publicationDate: null,
      },
    ]);
    setFormData([...formData, { title: '', description: '', mediaType: '', platform: 'Select Platform', publicationDate: null, files: [] }]);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = [...items];
    const updatedFormData = [...formData];
    updatedItems.splice(index, 1);
    updatedFormData.splice(index, 1);
    setItems(updatedItems);
    setFormData(updatedFormData);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer />
      {loading && <Box sx={{ position: 'fixed', height: "100vh", width: "100%", background: '#00000040', display: 'flex', top: '0', left: '0', zIndex: 999999 }}>
        <CircularProgress style={{ margin: "auto" }} />
      </Box>}
      <ArgonBox py={3} className="jobs">
        <Grid container spacing={2}>


          <Grid item xs={3}>
            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={1}>
                  <FormControl fullWidth>
                    <label htmlFor="client"> {t('Client')} *</label>
                    <select id="client" name="client" value={client} onChange={(e) => setClient(e.target.value)}>
                      <option value="">{t('Select Client')}</option>
                      {clientOption?.map((item) => {
                        return <option value={item?.id}>{item?.customerName}</option>
                      })}
                      {/* Add more options as needed */}
                    </select>
                  </FormControl>
                </ArgonBox>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3} pt={1}>
                  <FormControl fullWidth>
                    <label htmlFor="jobName" onClick={() => sendEmail()}> {t('Job Name')} *</label>
                    <input
                      type="text"
                      id="jobName"
                      name="jobName"
                      placeholder="Enter Job Name"
                      value={jobName}
                      onChange={(e) => setJobName(e.target.value)}
                    />
                  </FormControl>
                </ArgonBox>
              </Card>
            </ArgonBox>

            <ArgonBox mb={3}>
              <Card>
                <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3} pb={1}>
                  <span style={{ width: '100%' }}>
                    <h5>{t('Calendar')}</h5>
                    <div style={{ width: '100%', fontFamily: 'Arial, sans-serif' }}>
                      <FormControl fullWidth>
                        <label htmlFor="approvalDeadline" style={{ display: 'block', marginBottom: '8px', marginTop: '15px', fontWeight: '500' }}>
                          {t('Approval Deadline')}
                        </label>
                        <DatePicker
                          selected={selectedDate}
                          onChange={(date) => setSelectedDate(date)}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          dateFormat="MMMM d, yyyy h:mm aa"
                          placeholderText="Select"
                          className="custom-datepicker-input"
                          id="approvalDeadline"
                          name="approvalDeadline"
                          popperPlacement="auto" // Adjust position automatically
                          popperContainer={({ children }) => <div>{children}</div>} // Portal placement
                          portalId="root" // Ensure it's placed in a higher DOM level
                        />
                      </FormControl>
                    </div>
                  </span>
                </ArgonBox>
              </Card>
            </ArgonBox>
          </Grid>

          <Grid item xs={9}>
            {items?.map((i, index) => {
              return <div key={index} style={{ width: '100%', marginBottom: '15px' }}>
                <Card>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <ArgonBox display="flex" flexDirection="column" justifyContent="space-between" alignItems="center" p={3}>
                        <FormControl fullWidth>
                          <input
                            type="text"
                            id="title"
                            name="title"
                            placeholder="Title *"
                            value={formData[index]?.title}
                            onChange={(e) => handleInputChange(index, e)}
                          />
                        </FormControl>
                        <FormControl fullWidth style={{ marginTop: '5px' }}>
                          <textarea
                            id="description"
                            name="description"
                            value={formData[index]?.description}
                            onChange={(e) => handleInputChange(index, e)}
                            placeholder="Description"
                            style={{
                              width: '100%',
                              height: '150px',
                              padding: '10px',
                              fontSize: '16px',
                              borderRadius: '4px',
                              border: '1px solid #ccc',
                              boxSizing: 'border-box',
                              fontFamily: 'Arial, sans-serif',
                            }}
                          />
                        </FormControl>
                        <FormControl fullWidth style={{ marginTop: '5px' }}>
                          <label htmlFor="files" style={{ display: 'block', marginBottom: '8px', marginTop: '15px', fontWeight: '500' }}>
                            {t('Drag your files')}:
                          </label>
                          <div style={{ marginTop: '10px' }}>
                            {renderFileThumbnails(index)}
                          </div>
                          <Button
                            variant="contained"
                            component="label"
                            sx={{
                              width: '100px',
                              height: '100px',
                              minWidth: 'unset',
                              borderRadius: '8px',
                              border: "1px solid blue",
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              padding: 0,
                              backgroundColor: 'white !important', // Background color
                              color: 'black !important', // Icon color
                              '&:hover': {
                                backgroundColor: 'white !important', // Background color on hover
                                color: 'black !important', // Icon color on hover
                              },
                              "&:focus": {
                                boxShadow: 'none !important',
                              },
                              boxShadow: 'none', // Remove box shadow if needed
                            }}
                            onClick={() => { }}
                          >
                            <AddIcon fontSize="40px !important" sx={{ fontSize: 40 }} />
                            <input
                              type="file"
                              hidden
                              onChange={(e) => handleFileSelectFirstSection(index, e)}
                            />
                          </Button>
                        </FormControl>
                      </ArgonBox>
                      {formData?.length > 1 && <Button variant='text' style={{ color: 'red', fontSize: '10px', float: 'right' }}
                        onClick={() => handleRemoveItem(index)}
                      >{t('REMOVE ITEM')}</Button>}
                    </Grid>
                    <Grid item xs={4}>
                      <MediaButton
                        getIcon={getIcon}
                        formData={formData}
                        index={index}
                        handleMenuItemClick={(index, plat) => handleMenuItemClick(index, plat)}
                        isEdit={true}
                      />
                      {formData[index].platform.length ? (
                        <ArgonBox
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                          alignItems="center"
                          p={2}
                          pb={1}
                        >
                          <FormControl fullWidth>
                            <select
                              id="mediaType"
                              name="mediaType"
                              value={formData[index].mediaType}
                              onChange={(e) => handleInputChange(index, e)}
                            >
                              <option value="Post">Post</option>
                              {formData[index].platform.includes('Instagram') && <>
                                <option value="Reels">Reels</option>
                                <option value="Story">Story</option>

                                <option value="Carousel">Carousel</option>
                              </>
                              }
                            </select>
                          </FormControl>
                          <FormControl fullWidth>
                            <label
                              htmlFor="publicationDate"
                              style={{
                                display: "block",
                                marginBottom: "8px",
                                marginTop: "15px",
                                fontWeight: "500",
                              }}
                            >
                              {t('Publication Date')}
                            </label>
                            <DatePicker
                              selected={formData[index].publicationDate}
                              onChange={(date) => {
                                const updatedFormData = [...formData];
                                updatedFormData[index] = {
                                  ...updatedFormData[index],
                                  publicationDate: date,
                                };
                                setFormData(updatedFormData);
                              }}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              dateFormat="MMMM d, yyyy h:mm aa"
                              placeholderText="Publication Date"
                              className="custom-datepicker-input"
                              id="publicationDate"
                              name="publicationDate"
                              popperPlacement="auto" // Adjust position automatically
                              popperContainer={({ children }) => (
                                <div>{children}</div>
                              )} // Portal placement
                              portalId="root" // Ensure it's placed in a higher DOM level
                            />
                          </FormControl>
                          {(formData[index].platform.includes('Instagram') && formData[index].mediaType !== "Story") && (
                            <FormControl fullWidth>
                              <StyledCard
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                                sx={{
                                  minHeight: "200px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="h6" gutterBottom>
                                  {t('Drag or Click to select the file')}
                                </Typography>
                                <input
                                  type="file"
                                  multiple
                                  accept="image/*"
                                  onChange={(e) =>
                                    handleFileSelectSecondSection(index, e)
                                  }
                                  style={{ display: "none" }}
                                  id="file-input"
                                />
                                <label htmlFor="file-input">
                                  <Button
                                    variant="contained"
                                    component="span"
                                    sx={{ marginTop: 2 }}
                                    style={{ color: 'white' }}
                                  >
                                    {t('Select Images')}
                                  </Button>
                                </label>
                                {formData[index]?.filesSecondSection?.length > 0 && (
                                  <Box sx={{ marginTop: 2, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                                    {formData[index].filesSecondSection.map((file, index) => {
                                      if (typeof (file) === 'object') {
                                        return (
                                          <div key={file.name} style={{ position: 'relative', display: 'inline-block', margin: '10px' }}>
                                            <img
                                              src={URL.createObjectURL(file)}
                                              alt={file.name}
                                              style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '8px' }}
                                            />
                                            <IconButton
                                              style={{
                                                position: 'absolute',
                                                top: '5px',
                                                right: '5px',
                                                height: '25px',
                                                width: "25px",
                                                color: 'white',
                                                backgroundColor: 'black',
                                                padding: '5px',
                                              }}
                                              onClick={() => handleRemoveFiledrag(index)}
                                            >
                                              <ClearIcon />
                                            </IconButton>
                                          </div>
                                        )
                                      } else {
                                        return (
                                          <div key={file.name} style={{ position: 'relative', display: 'inline-block', margin: '10px' }}>
                                            <img
                                              src={`${BASE_URL}${file}`}
                                              alt={''}
                                              style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '8px' }}
                                            />
                                            <IconButton
                                              style={{
                                                position: 'absolute',
                                                top: '5px',
                                                right: '5px',
                                                height: '25px',
                                                width: "25px",
                                                color: 'white',
                                                backgroundColor: 'black',
                                                padding: '5px',
                                              }}
                                              onClick={() => deleteImages('secondFiles', file)}
                                            >
                                              <ClearIcon />
                                            </IconButton>
                                          </div>
                                        )
                                      }

                                    }
                                    )}
                                  </Box>
                                )}


                                  
                              </StyledCard>
                            </FormControl>
                          )}
                        </ArgonBox>
                      ) : ""}


                    </Grid>
                  </Grid>
                </Card>
                <div style={{ display: 'flex', flexDirection: "row-reverse" }}>

                  <Button
                    variant='text'
                    size="small"
                    style={{ color: 'blue', float: 'right', marginTop: '10px' }}
                    startIcon={<AddIcon />}
                    onClick={addItem}
                  >
                    {t('Add Item')}
                  </Button>
                </div>
              </div>
            })}
            {/* {
              jobData?.status === "Draft" && <Button
                variant='contained'
                size="small"
                style={{ color: 'white', float: 'right', marginTop: '10px', marginLeft: '10px' }}
                onClick={() => submitForApproval()}
              >
                Submit for approval
              </Button>

            } */}

            <Button
              variant='contained'
              size="small"
              style={{ color: 'white', float: 'right', marginTop: '10px' }}
              onClick={prepareForApiCall}
            >
              {t('Save')}
            </Button>

          </Grid>
        </Grid>
      </ArgonBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Tables;
